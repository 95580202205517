<template>
  <div v-if="enabled" class="navigation-loader">
    <div class="navigation-loader-progress" />
  </div>
</template>

<script lang="ts">
/**
 * Navigation loader component
 * @version 1.0.0 - 2022-09-22
 */
import { defineComponent } from "vue";
import { useLoaderStore } from "@/store/LoaderStore";

export default defineComponent({
  name: "NavigationLoader",
  computed: {
    enabled(): boolean {
      return useLoaderStore().isEnabled();
    },
  },
});
</script>
<style lang="scss" scoped>
.navigation-loader {
  @apply absolute top-16 left-0 w-full h-0.5 overflow-hidden z-10;

  .navigation-loader-progress {
    @apply bg-gradient-to-l from-jb-pink-100 to-white rounded-full relative h-0.5 w-2/3;
    animation: infinity 1s linear infinite;
  }
}

@keyframes infinity {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>
