<template>
  <transition name="fade" mode="out-in">
    <component :is="layout" v-if="layout" />
  </transition>
  <NavigationLoader />
  <DsToastNotifier />
  <VersionManagerNotifier />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageNotFoundLayout from "@/shared/modules/page-not-found/components/PageNotFoundLayout.vue";

import { DsToastNotifier } from "@devsalsa/vue-core";

import AccountLayout from "@/core/modules/account/components/AccountLayout.vue";
import AuthenticatedLayout from "@/core/shared/components/Layout/AuthenticatedLayout.vue";
import NavigationLoader from "@/core/shared/components/Navigation/NavigationLoader.vue";
import VersionManagerNotifier from "@/core/shared/components/Toast/VersionManagerNotifier.vue";

export default defineComponent({
  components: {
    AuthenticatedLayout,
    AccountLayout,
    PageNotFoundLayout,
    DsToastNotifier,
    VersionManagerNotifier,
    NavigationLoader,
  },
  data() {
    return {
      layout: "",
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.meta.layout !== undefined) {
          this.layout = to.meta.layout;
        } else {
          this.layout = "AuthenticatedLayout";
        }
      },
    },
  },
});
</script>

<style lang="scss">
.app-content {
  margin-top: 64px;
  min-height: calc(100vh - 192px);

  @screen sm {
    min-height: calc(100vh - 128px);
  }
}
</style>
