import * as Sentry from "@sentry/browser";
import {
  BadRequestApiServiceError,
  MaintenanceApiServiceError,
  UnauthorizedApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";
import AuthService from "@/core/shared/services/Auth/AuthService";
import type { ApiErrorResponse } from "@/core/shared/services/ApiService";
import { getUnixTime } from "date-fns";

export class ApiErrorHandler {
  static handle(context: XMLHttpRequest): void {
    let response: ApiErrorResponse;

    if (!context.status) {
      //Network issue
      response = {
        code: "network_error",
        message: "Network error",
        data: [],
        ts: +new Date(),
      };
    } else {
      try {
        response = JSON.parse(context.response);
      } catch (e) {
        response = {
          code: "invalid_response",
          message: "Invalid response",
          data: {
            response: context,
          },
          ts: getUnixTime(new Date()),
        };
      }
    }

    Sentry.setContext("xhr", {
      endpoint: context.responseURL,
      token: AuthService.getToken() || "",
      response: {
        code: response.code,
        message: response.message,
        data: JSON.stringify(response.data),
      },
    });

    //Unauthenticated user.
    if ([401].includes(context.status)) {
      throw new UnauthorizedApiServiceError(
        response.code,
        response.message,
        response.data
      );
    }

    //API maintenance.
    if ([503].includes(context.status)) {
      throw new MaintenanceApiServiceError(
        response.code,
        response.message,
        response.data
      );
    }

    //A bad request could be a 400, 404 or 500 error.
    throw new BadRequestApiServiceError(
      response.code,
      response.message,
      response.data
    );
  }
}
