<template>
  <div class="auth-navbar">
    <div>
      <button
        type="button"
        class="text-gray-500 flex items-center justify-center focus:outline-none focus:ring-0 focus:ring-inset lg:hidden"
        @click="toggleSidebar"
      >
        <span class="sr-only">Open sidebar</span>
        <MenuSidebarIcon class="h-6 w-6 stroke-gray-800" />
      </button>
      <RouterLink class="lg:flex hidden" :to="{ name: 'Dashboard' }">
        <slot>
          <LogoWithTextIcon class="w-32 h-auto" />
        </slot>
      </RouterLink>
    </div>
    <DsDropdown :items="avatarLinks">
      <template #button>
        <DsAvatar :alt="fullName" size="sm" layout="rounded" />
      </template>
    </DsDropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Global from "@/core/shared/services/Provide/Global";
import { isSidebarOpen } from "@/shared/components/sidebar/SidebarListener";

import type { DsDropdownItem } from "@devsalsa/vue-core";
import { DsAvatar, DsDropdown } from "@devsalsa/vue-core";

import LogoWithTextIcon from "@/shared/components/icon/LogoWithTextIcon.vue";
import MenuSidebarIcon from "@/shared/components/icon/MenuSidebarIcon.vue";

export default defineComponent({
  name: "AuthNavbar",
  components: {
    DsAvatar,
    DsDropdown,
    MenuSidebarIcon,
    LogoWithTextIcon,
  },
  data() {
    return {
      fullName: "",
      avatarLinks: [] as DsDropdownItem[],
      isSidebarOpen: isSidebarOpen,
    };
  },
  async created() {
    const { name } = await Global.getAccountInfo();
    this.fullName = name;
    this.avatarLinks = [
      {
        label: this.$t("common.route.profile.link"),
        route: {
          name: "Profile",
        },
      },
      {
        label: this.$t("common.route.logout.link"),
        route: {
          name: "Logout",
        },
        cssClasses: "border-t border-gray-100",
      },
    ];
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
});
</script>
<style lang="scss" scoped>
.auth-navbar {
  @apply sticky top-0 z-20 flex justify-between items-center px-4 bg-white h-16 border-b sm:px-6 lg:px-8;
}
</style>
