import { DsNotifier } from "@devsalsa/vue-core";
import { useApiStore } from "@/store/ApiStore";
import { useDsToastNotifierStore } from "@devsalsa/vue-core/dist/components/toast/DsToastNotifierStore";
import { ApiErrorTranslator } from "@/core/shared/helpers/Error/ApiErrorTranslator";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

export class MaintenanceHandler {
  static setOn(error: ApiServiceError) {
    useApiStore().setMaintenanceOn();
    if (useDsToastNotifierStore().maintenanceNotificationId === 0) {
      const id = DsNotifier.sendInfo({
        message: ApiErrorTranslator.translate(error),
        duration: 0,
        enableClose: false,
        position: "top-0 right-0",
        overlay: true,
      });
      useDsToastNotifierStore().setMaintenanceId(id);
    }
  }
  static setOff() {
    useApiStore().setMaintenanceOff();
    DsNotifier.delete(useDsToastNotifierStore().maintenanceNotificationId);
    useDsToastNotifierStore().setMaintenanceId(0);
  }
}
