<template>
  <div>
    <TransitionRoot as="template" :show="isSidebarOpen">
      <Dialog as="div" class="relative z-40 lg:hidden" @close="closeSidebar()">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900 bg-opacity-30" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-white py-4"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="closeSidebar"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <CloseOutlineIcon
                      class="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </TransitionChild>
              <div class="px-4 flex">
                <JoinbrandsLogoIcon class="w-[33px] h-11 flex-shrink-0" />
              </div>
              <div class="mt-5 h-0 flex-1 overflow-y-auto">
                <SidebarItems />
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Static sidebar for desktop -->
    <div
      class="fixed z-10 hidden h-full bg-white border-r border-gray-200 w-60 lg:flex-1 lg:flex"
    >
      <div class="flex flex-grow flex-col overflow-y-auto bg-white pt-5">
        <SidebarItems />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SidebarItems from "@/shared/components/sidebar/SidebarItems.vue";
import { isSidebarOpen } from "@/shared/components/sidebar/SidebarListener";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import CloseOutlineIcon from "@/shared/components/icon/CloseOutlineIcon.vue";
import JoinbrandsLogoIcon from "@/shared/components/icon/JoinbrandsLogoIcon.vue";

export default defineComponent({
  name: "Sidebar",
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    SidebarItems,
    CloseOutlineIcon,
    JoinbrandsLogoIcon,
  },
  data() {
    return {
      isSidebarOpen: isSidebarOpen,
    };
  },
  methods: {
    closeSidebar() {
      this.isSidebarOpen = false;
    },
  },
});
</script>
