import { ref } from "vue";
import * as Sentry from "@sentry/vue";
import AccountService from "@/core/modules/account/services/AccountService";
import type { AccountInfo } from "@/core/modules/account/services/AccountService";

export default class Global {
  private static accountInfo: { value: null | AccountInfo } = ref(null);

  /**
   *
   * @param update
   * @returns
   */
  static async getAccountInfo(update = false) {
    if (!this.accountInfo.value || update) {
      this.accountInfo.value = await AccountService.getInfo();
      if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
        Sentry.setUser({ email: this.accountInfo.value.email });
      }
    }
    return this.accountInfo.value;
  }
}
