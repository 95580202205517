<template>
  <div class="page-not-found-layout">
    <UnauthenticatedNavbar />
    <div
      class="app-content flex max-h-full justify-center items-center bg-gray-50 lg:bg-white"
    >
      <div class="w-full sm:w-3/5 2xl:w-2/5 sm:py-12">
        <div
          class="flex flex-col lg:flex-row sm:rounded-2xl sm:shadow-lg lg:shadow-xl xl:shadow-2xl"
        >
          <div
            class="hidden sm:block lg:w-1/3 bg-cover bg-right-top bg-origin-border rounded-t-none sm:rounded-t-2xl lg:rounded-tr-none lg:rounded-l-2xl w-full h-28 lg:ml-0 lg:h-auto"
            :style="bgProps"
          ></div>
          <div
            class="shadow sm:shadow-none bg-white rounded-2xl m-3 sm:m-0 p-4 md:p-6 sm:p-8 xl:p-10 lg:w-2/3"
          >
            <RouterView v-slot="{ Component, route }">
              <transition name="fade" mode="out-in">
                <component :is="Component" :key="route.name" />
              </transition>
            </RouterView>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UnauthenticatedNavbar from "@/shared/components/navbar/UnauthenticatedNavbar.vue";
import backgroundImage from "@/shared/modules/page-not-found/assets/page-not-found.webp";

import Footer from "@/core/shared/components/Layout/Footer.vue";

export default defineComponent({
  name: "PageNotFoundLayout",
  components: { Footer, UnauthenticatedNavbar },
  data() {
    return {
      bgProps: {
        backgroundImage: `url(${backgroundImage})`,
      },
    };
  },
});
</script>
