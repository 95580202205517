import type { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import AuthService from "@/core/shared/services/Auth/AuthService";
import Global from "@/core/shared/services/Provide/Global";

export default class AuthGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static withoutToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    if (AuthService.isAuthenticated()) {
      return next({
        name: "Dashboard",
      });
    }
    return next();
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    if (AuthService.isAuthenticated()) {
      const accountInfo = await Global.getAccountInfo();
      if (
        to.meta.permission != null &&
        !Object.values(accountInfo.permissions).includes(
          String(to.meta.permission)
        )
      ) {
        return next({
          name: "Dashboard",
        });
      }

      return next();
    }

    return next({
      name: "Login",
    });
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withEditPermissions(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    if (AuthService.isAuthenticated()) {
      const accountInfo = await Global.getAccountInfo();
      if (accountInfo.id == Number(to.params.id)) {
        return next({
          name: "Administrators",
        });
      }
      if (
        to.meta.permission != null &&
        !Object.values(accountInfo.permissions).includes(
          String(to.meta.permission)
        )
      ) {
        return next({
          name: "Dashboard",
        });
      }

      return next();
    }

    return next({
      name: "Login",
    });
  }
}
