{
  "common": {
    "recurringCampaignType": {
      "tiktok": "TikTok",
      "tiktok_shop": "TikTok Shop",
      "instagram": "Instagram",
      "tiktok_instagram": "TikTok + Instagram"
    },
    "slotFrequency": {
      "daily": "Once a day",
      "every_other_day": "Every second day",
      "every_third_day": "Every third day",
      "twice_a_day": "Twice a day"
    },
    "form": {
      "input": {
        "email": {
          "label": "Email address",
          "placeholder": "email{'@'}domain.tld",
          "validation": {
            "required": "The email address is required.",
            "invalid": "The email address is invalid."
          }
        },
        "password": {
          "label": "Password",
          "placeholder": "{minStringChars} characters minimum",
          "validation": {
            "required": "The password is required.",
            "min": "The minimum number of characters required is {minStringChars}.",
            "regex": "The password must have 2 uppercase, 2 lowercase 2 numbers minimum."
          }
        },
        "aboutMe": {
          "label": "About me",
          "placeholder": "About me"
        },
        "firstName": {
          "label": "First name",
          "placeholder": "First name",
          "validation": {
            "required": "The first name is required."
          }
        },
        "lastName": {
          "label": "Last name",
          "placeholder": "Last name",
          "validation": {
            "required": "The last name is required."
          }
        },
        "dateBirth": {
          "label": "Date of birth"
        },
        "phoneNumber": {
          "label": "Phone number",
          "placeholder": "0123456789",
          "validation": {
            "required": "The phone number is required."
          }
        },
        "addressLine1": {
          "label": "Address line 1",
          "placeholder": "Address line 1",
          "validation": {
            "required": "The address line 1 is required."
          }
        },
        "addressLine2": {
          "label": "Address line 2",
          "placeholder": "Address line 2",
          "validation": {
            "required": "The address line 2 is required."
          }
        },
        "city": {
          "label": "City/Town",
          "placeholder": "City/Town",
          "validation": {
            "required": "The city/town is required."
          }
        },
        "state": {
          "label": "State",
          "placeholder": "State",
          "validation": {
            "required": "The state is required."
          }
        },
        "zip": {
          "label": "Zip code",
          "placeholder": "Zip code",
          "validation": {
            "required": "The zip is required."
          }
        },
        "uploadVideo": {
          "label": "Chose a video",
          "typeFile": "16:9 or 9:16\nResolution 1080p or higher\nDuration 15-30 sec.\n"
        },
        "uploadID": {
          "label": "Your ID",
          "typeFile": "PNG, JPG up to 10MB"
        },
        "url": {
          "placeholder": "Type your {company} URL"
        },
        "checkbox": {
          "label": "I accept the terms",
          "incorrect": "Incorrect?"
        },
        "createdOn": {
          "label": "Created on"
        },
        "linkedIn": {
          "label": "LinkedIn"
        },
        "search": {
          "placeholder": "Search by ID ({minStringChars} character minimum)",
          "validation": {
            "required": "The search is required.",
            "minStringChars": "The search must have at least {minStringChars} characters."
          }
        },
        "imageUpload": {
          "label": "Upload an image",
          "fileType": "{extensions} up to 50MB",
          "minimumResolution": "Minimum resolution {minWidth}×{minHeight} or higher.",
          "cropperMinimumResolution": "Resolution min {minWidth}×{minHeight} and max {maxWidth}×{maxHeight}.",
          "validation": {
            "required": "The image is required.",
            "format": "The file format is not supported.",
            "size": "The file size is larger than the maximum size allowed.",
            "minResolution": "The file resolution is too low ({minWidth}x{minHeight}).",
            "maxResolution": "The file resolution is too high ({maxWidth}x{maxHeight})."
          }
        }
      },
      "select": {
        "gender": {
          "label": "Gender",
          "placeholder": "Select your gender",
          "validation": {
            "required": "The gender is required."
          },
          "options": {
            "1": "Male",
            "2": "Female",
            "3": "Non-binary"
          }
        },
        "country": {
          "label": "Country",
          "placeholder": "Select your country",
          "validation": {
            "required": "The country is required."
          }
        },
        "state": {
          "label": "State",
          "placeholder": "Select your state",
          "validation": {
            "required": "The state is required."
          }
        },
        "primaryCategory": {
          "label": "Primary category",
          "options": {
            "beauty": "Beauty",
            "sports": "Sports",
            "food": "Food"
          },
          "validation": {
            "required": "The primary category is required."
          }
        },
        "secondaryCategory": {
          "label": "Secondary category",
          "validation": {
            "required": "The secondary category is required."
          }
        },
        "thirdCategory": {
          "label": "Third category",
          "validation": {
            "required": "The third category is required."
          }
        },
        "ethnicity": {
          "label": "Ethnicity",
          "placeholder": "Select ethnicity",
          "options": {
            "0": "No selected",
            "1": "White",
            "2": "Hispanic",
            "3": "African american",
            "4": "Asian",
            "5": "Middle eastern"
          },
          "validation": {
            "required": "The ethnicity is required."
          }
        }
      },
      "textarea": {
        "message": {
          "title": "Message",
          "notes": {
            "placeholder": "Enter the description"
          },
          "placeholder": "The message should be clear, with good spelling (it will be sent to the user).",
          "suspend": {
            "placeholder": "Enter the reason why you are suspending the user."
          },
          "softBan": {
            "placeholder": "Enter the reason why you are soft banning the creator."
          }
        },
        "validation": {
          "required": "This message is required.",
          "minStringChars": "The message must have at least {minStringChars} characters.",
          "maxStringChars": "The message must not exceed {maxStringChars} characters."
        }
      },
      "textEditor": {
        "characters": "Characters: {currentCharacters} / {characters}"
      },
      "checkbox": {
        "validation": {
          "required": "You must select at least one option."
        }
      },
      "button": {
        "download": "Download"
      }
    },
    "gender": {
      "title": "Gender",
      "0": "Doesn't matter",
      "1": "Male",
      "2": "Female",
      "3": "Non-binary"
    },
    "age": {
      "title": "Age",
      "1": "18-24",
      "2": "25-34",
      "3": "35-44",
      "4": "45+"
    },
    "ethnicity": {
      "title": "Ethnicities",
      "1": "White",
      "2": "Hispanic",
      "3": "African American",
      "4": "Asian",
      "5": "Middle Eastern"
    },
    "level": {
      "1": "Level 1",
      "2": "Level 2",
      "3": "Level 3",
      "pro": "PRO"
    },
    "button": {
      "login": "Sign in",
      "continue": "Continue",
      "loading": "Loading...",
      "searching": "Searching...",
      "confirm": "Confirm",
      "resetPassword": "Reset password",
      "save": "Save",
      "edit": "Edit",
      "ban": "Ban",
      "process": "Process",
      "retry": "Retry",
      "approve": "Approve",
      "reject": "Reject",
      "deny": "Deny",
      "next": "Next",
      "previous": "Previous",
      "suspend": "Suspend",
      "unsuspend": "Unsuspend",
      "update": "Update",
      "submit": "Submit",
      "home": "Go back home",
      "manageWallet": "Manage wallet",
      "loginCreator": "Login as this creator",
      "loginCompany": "Login as this company",
      "add": "Add",
      "remove": "Remove",
      "skip": "Skip",
      "softBan": "Soft ban",
      "actions": "Actions",
      "unSoftBan": "Remove soft ban",
      "resolutionCenter": "Go to resolution center",
      "cancel": "Cancel",
      "addCode": "Add code",
      "addFunds": "Add funds",
      "removeFunds": "Remove funds",
      "setPro": "Set PRO",
      "unSetPro": "Remove PRO",
      "showMore": "Show more",
      "clear": "Clear",
      "create": "Create",
      "tooltips": {
        "loginAs": "Login as {name}",
        "suspend": "Suspend {name}",
        "unsuspend": "Unsuspend {name}",
        "resetTOS": "Reset the TOS violation for the job.",
        "editCoupon": "Edit coupon",
        "EditAdministrator": "Edit Administrator",
        "process": "Process portfolio report",
        "creatorSummary": "Creator Summary"
      },
      "changePaymentMethod": "Change payment method",
      "SeeDetails": "See details",
      "contractDetails": "Job details",
      "leaveReview": "Leave a review",
      "offerDetails": "Offer details",
      "inviteMoreCreators": "Invite more creators",
      "cancelOffer": "Cancel offer",
      "resendOffer": "Resend offer",
      "approveOffer": "Approve offer",
      "counterOffer": "Counter offer",
      "denyOffer": "Deny offer",
      "startJob": "Start job",
      "cancelJob": "Cancel job",
      "confirmShipping": "Confirm shipping",
      "goCalendar": "Go to calendar",
      "chat": "Chat",
      "end": "End"
    },
    "error": {
      "global": {
        "internalServerError": "We currently cannot process your request, our technical staff has been notified about this problem (incident code: {id}) and they are on it!",
        "connectivityError": "We encounter connectivity problems. Retrying...",
        "networkError": "A network error occurred when processing your request. Please retry or contact our support.",
        "offline": "It looks like you are offline. Please check your internet connection...",
        "maintenance": "{project} is undergoing maintenance, please check back later.",
        "upgrading": "{project} is being updated, please be patient...",
        "notAuthorized": "You have been logged out to protect your account, please login again.",
        "userSuspended": "There is an issue with your account, please contact our support team.",
        "invalidEmailPasswordCombination": "The credentials you provided are incorrect.",
        "invalidInput": "Some information that you provided is missing or invalid.",
        "permissionMissing": "You don't have enough permissions to access that resource.",
        "productNotFound": "There is not job to mediate.",
        "endpointNotFound": "The API resource is not available.",
        "invalidResponse": "An unexpected error occurred when processing your request. Please retry or contact our support.",
        "adminExists": "An administrator already exists with the email address provided.",
        "creatorNotFound": "The creator is invalid or can't be found.",
        "videoNotFound": "The video is invalid or can't be found.",
        "creatorAlreadyApproved": "The creator has already been approved.",
        "campaignBundleCouponExists": "The coupon code already exists.",
        "campaignBundleCouponInvalidPercentage": "The coupon percentage cannot be applied to the selected bundles and job types",
        "campaignBundleCoupon": "The bundle selected for the coupon did not match with the job type",
        "wrongJobStatus": "The status of the job is invalid. Please refresh your page.",
        "wrongJobContentStatus": "The status of the job content is invalid. Please refresh your page.",
        "ipDetailNotFound": "No records were found for the IP address",
        "secretApiError": "The security key is invalid.",
        "chatMessageNotFound": "Chat message not found."
      },
      "validation": {
        "required": "The field is required.",
        "maxStringChars": "The maximum number of characters allowed is {maxStringChars}.",
        "minStringChars": "The minimum number of characters required is {minStringChars}."
      }
    },
    "system": {
      "version": "New version",
      "updateMessage": "There is a new version of the system, please press update."
    },
    "toast": {
      "information": "Information",
      "warning": "Warning",
      "error": "Error",
      "success": "Success"
    },
    "route": {
      "dashboard": {
        "meta": "Dashboard",
        "link": "Dashboard"
      },
      "administrators": {
        "meta": "Administrators",
        "link": "Administrators"
      },
      "profile": {
        "meta": "Profile",
        "link": "Profile"
      },
      "logout": {
        "link": "Log out"
      },
      "pageNotFound": {
        "meta": "Page not found"
      },
      "createAdministrator": {
        "meta": "Create administrator",
        "link": "Create administrator"
      },
      "editAdministrator": {
        "meta": "Edit administrator",
        "link": "Edit administrator"
      },
      "editAdministratorPermissions": {
        "meta": "Edit administrator permissions",
        "link": "Edit administrator permissions"
      },
      "creators": {
        "meta": "Creators",
        "link": "Creators"
      },
      "pendingCreators": {
        "meta": {
          "title": "Creators pending approval"
        }
      },
      "pendingAmazonInfluencers": {
        "meta": {
          "title": "Amazon influencers pending approval"
        }
      },
      "companies": {
        "meta": "Companies",
        "link": "Companies"
      },
      "imageSelfieProductApproval": {
        "meta": "Selfie product approval",
        "link": "Selfie product approval"
      },
      "imageProductDemo": {
        "meta": "Product demo approval",
        "link": "Product demo approval"
      },
      "imageProductOnly": {
        "meta": "Product only approval",
        "link": "Product only approval"
      },
      "imageLifestyleShot": {
        "meta": "Lifestyle shot approval",
        "link": "Lifestyle shot approval"
      },
      "videoProductDemoApproval": {
        "meta": "Product demo approval",
        "link": "Product demo approval"
      },
      "videoHowToApproval": {
        "meta": "How-to video approval",
        "link": "How-to video approval"
      },
      "videoUnboxingApproval": {
        "meta": "Unboxing video approval",
        "link": "Unboxing video approval"
      },
      "videoProductReviewApproval": {
        "meta": "Product review approval",
        "link": "Product review approval"
      },
      "certifications": {
        "meta": "Certifications",
        "link": "Certifications"
      },
      "campaigns": {
        "meta": "Campaigns",
        "link": "Campaigns"
      },
      "recurringCampaigns": {
        "meta": "Recurring campaigns",
        "link": "Recurring campaigns"
      },
      "contents": {
        "meta": "Content approval",
        "link": "Contents"
      },
      "jobs": {
        "meta": "Jobs",
        "link": "Jobs"
      },
      "recurringJobs": {
        "meta": "Recurring jobs",
        "link": "Recurring jobs"
      },
      "lateJobs": {
        "meta": {
          "title": "Late jobs"
        },
        "link": "Late jobs"
      },
      "wireTransfers": {
        "meta": "Wire transfers",
        "link": "Wire transfers"
      },
      "videos": {
        "meta": "Videos",
        "link": "Videos"
      },
      "flaggedChats": {
        "meta": {
          "title": "Flagged chats"
        },
        "link": "Flagged chats"
      },
      "tiktokAds": {
        "meta": {
          "title": "TikTok ads center"
        },
        "link": "TikTok ads center"
      },
      "tiktokAdApproval": {
        "meta": {
          "title": "TikTok ad approval"
        },
        "link": "TikTok ad approval"
      },
      "pendingTikTokAds": {
        "meta": {
          "title": "TikTok ads pending approval"
        },
        "link": "TikTok ads pending approval"
      },
      "companyWithdrawals": {
        "meta": {
          "title": "Company Withdrawals"
        },
        "link": "Company Withdrawals"
      },
      "companyWithdrawalApproval": {
        "meta": {
          "title": "Withdrawal approval"
        },
        "link": "Withdrawal approval"
      },
      "creatorPayments": {
        "meta": {
          "title": "Creators payments pending approval"
        },
        "link": "Creators payments pending approval"
      },
      "creatorPaymentApproval": {
        "meta": {
          "title": "Creator payment pending approval"
        },
        "link": "Creator payment pending approval"
      },
      "coupons": {
        "meta": "Blast coupons",
        "link": "Blast coupons"
      },
      "companyWatermarks": {
        "meta": "Watermarks",
        "link": "Watermarks"
      },
      "companyWatermarkApproval": {
        "meta": "Watermarks approval",
        "link": "Watermarks approval"
      },
      "ipSearch": {
        "meta": "IP addresses",
        "link": "IP addresses"
      }
    },
    "text": {
      "details": {
        "title": {
          "logs": {
            "h2": "Logs"
          },
          "transactions": {
            "h2": "Transactions"
          },
          "personalInformation": {
            "h3": "Personal information"
          },
          "brandInformation": {
            "h3": "Brand information"
          },
          "campaignInformation": {
            "h3": "Campaign information"
          },
          "amountInformation": {
            "h3": "Amount information"
          }
        },
        "id": {
          "label": "ID"
        },
        "brand": {
          "label": "Brand"
        },
        "name": {
          "label": "Name"
        },
        "status": {
          "label": "Status"
        },
        "statusApproval": {
          "label": "Status approval"
        },
        "firstName": {
          "label": "First name"
        },
        "lastName": {
          "label": "Last name"
        },
        "email": {
          "label": "Email address"
        },
        "phoneNumber": {
          "label": "Phone number"
        },
        "createdOn": {
          "label": "Created on"
        },
        "linkedIn": {
          "label": "LinkedIn"
        },
        "wallet": {
          "label": "Wallet"
        },
        "walletEarningsBalance": {
          "label": "Wallet earnings balance"
        },
        "walletReimbursementsBalance": {
          "label": "Wallet reimbursements balance"
        },
        "type": {
          "label": "Type"
        },
        "product": {
          "label": "Product"
        },
        "link": {
          "label": "Product link"
        },
        "price": {
          "label": "Price"
        },
        "content": {
          "label": "Required content"
        },
        "settings": {
          "label": "Settings"
        },
        "description": {
          "title": "Description",
          "label": "Description"
        },
        "image": {
          "label": "Image"
        },
        "upsell": {
          "label": "Upsell"
        },
        "creatorsRequired": {
          "label": "Creator required | Creators required"
        },
        "totalEarnings": {
          "label": "Total Earnings"
        },
        "creatorApproval": {
          "label": "Creator approval"
        },
        "level": {
          "label": "Level"
        },
        "caption": {
          "label": "Caption"
        },
        "references": {
          "label": "References"
        },
        "cancellationReason": {
          "label": "Cancellation reason"
        },
        "pauseReason": {
          "label": "Pause reason"
        },
        "videoDuration": {
          "label": "Video duration"
        },
        "reimbursement": {
          "label": "Reimbursement"
        },
        "shippingAmount": {
          "label": "Shipping amount"
        },
        "taxesAmount": {
          "label": "Taxes"
        },
        "campaignType": {
          "1": "Product delivery",
          "2": "Product reimbursement",
          "label": "Campaign type"
        },
        "creatorCriteria": {
          "label": "Creator criteria"
        },
        "requiredContent": {
          "label": "Required content"
        },
        "ThirtyDaysRating": {
          "label": "Ratings last 30 days"
        },
        "totalReviews": {
          "label": "Total reviews"
        },
        "ratings": {
          "label": "Ratings"
        },
        "avgStars": {
          "label": "Stars"
        },
        "amount": {
          "label": "Amount"
        },
        "key": {
          "label": "Security key"
        },
        "code": {
          "label": "Code"
        },
        "specialRequirements": {
          "label": "Special requirements"
        },
        "expressJob": "Express Job"
      },
      "address": {
        "title": "Address"
      },
      "aboutMe": {
        "title": "About me"
      },
      "socialNetworks": {
        "title": "Social media links"
      },
      "categories": {
        "title": "Categories"
      },
      "certification": {
        "title": "Certification"
      },
      "rating": {
        "title": "Rating"
      },
      "wallet": {
        "title": "Wallet"
      },
      "active": "Active",
      "canceled": {
        "canceled": "Canceled",
        "1": "Canceled by the creator",
        "2": "Canceled by the brand"
      },
      "suspended": "Suspended",
      "deleted": "Deleted",
      "verified": "Verified",
      "notVerified": "Not verified",
      "yes": "Yes",
      "no": "No",
      "review": "Review",
      "softBanned": "Soft banned",
      "nrCampaigns": "# campaigns: {amount}",
      "approve": "Approve",
      "deny": "Deny"
    },
    "url": {
      "socialInstagramUrl": "Social Instagram URL",
      "socialTwitterUrl": "Social Twitter URL",
      "socialAmazonUrl": "Social Amazon URL",
      "socialYouTubeUrl": "Social YouTube URL",
      "socialTikTokUrl": "Social TikTok URL",
      "socialBlogUrl": "Social Blog URL"
    },
    "label": {
      "status": "Status",
      "address": "Address",
      "copyAddress": "Copy address",
      "copied": "Copied",
      "edit": "Edit",
      "managePermissions": "Manage permissions"
    },
    "tooltip": {
      "message": "You have to decline the content before you can cancel the job"
    },
    "jobs": "{count} Job | {count} jobs",
    "certification": {
      "1": {
        "title": "How-to video",
        "description": "Detailed video tutorial to guide customers through all steps of using your product."
      },
      "2": {
        "title": "Unboxing video",
        "description": "Entertaining unboxing video showing the packaging and what comes along with the product."
      },
      "3": {
        "title": "Product review",
        "description": "Positive feedback videos where creators spread the word about how awesome your product is."
      },
      "4": {
        "title": "Product demo",
        "description": "Detailed on-camera demonstration of how your product works and looks like in real life."
      },
      "5": {
        "title": "Lifestyle shot",
        "description": "Captions of your product being used in real-life activities."
      },
      "6": {
        "title": "Selfie with product",
        "description": "Selfies with content creators and your product in focus."
      },
      "7": {
        "title": "Product demo",
        "description": "Detailed product demonstration of what your product can do."
      },
      "8": {
        "title": "Product only",
        "description": "Images showing your product in the best way possible."
      },
      "9": {
        "title": "TikTok video",
        "description": ""
      },
      "10": {
        "title": "Instagram Reel",
        "description": ""
      },
      "11": {
        "title": "YouTube Short",
        "description": ""
      },
      "12": {
        "title": "Amazon Shoppable video",
        "description": ""
      },
      "13": {
        "title": "TikTok Shop video",
        "description": ""
      }
    },
    "contentType": {
      "image": "Image",
      "video": "Video"
    },
    "imageSlider": {
      "title": "Image preview",
      "error": "This file has not a valid image format."
    },
    "videoSlider": {
      "title": "Video preview",
      "error": "This file has not a valid video format."
    }
  },
  "core": {
    "administrator": {
      "form": {
        "input": {
          "name": {
            "label": "Name",
            "placeholder": "Enter your name",
            "validation": {
              "required": "The name is required.",
              "invalid": "The name is invalid."
            }
          }
        }
      },
      "permissions": {
        "manage_admin": "Manage admin",
        "manage_creator": "Manage creator",
        "manage_video": "Manage video",
        "impersonate_company": "Impersonate company",
        "campaign_approval": "Campaign approval",
        "job_data": "Job data",
        "secret_client": "Secret client",
        "job_certification": "Job certification",
        "job_cancellation": "Job cancellation",
        "manage_company": "Manage company",
        "impersonate_creator": "Impersonate creator",
        "job_content_approval": "Job content approval",
        "job_mediation": "Job mediation",
        "wire_transfer": "Wire transfer",
        "manage_tiktok_ad": "TikTok ad",
        "bundle_coupons": "Coupons",
        "recurring_campaign": "Manage recurring campaign",
        "text": {
          "label": "Permissions"
        }
      }
    },
    "shared": {
      "views": {
        "notAvailable": "N/A"
      },
      "components": {
        "dataTable": {
          "suspendedLabel": {
            "yes": "Yes",
            "no": "No"
          },
          "validation": {
            "minStringChars": "The minimum number of characters required is {minStringChars}.",
            "maxStringChars": "The maximum number of characters allowed is {maxStringChars}.",
            "notFound": "Sorry, but nothing matched your search terms. Please change your filtering selections and try again."
          },
          "dateCell": {
            "dateStart": "Date start: {date}",
            "dateEnd": "Date end: {date}"
          }
        },
        "layout": {
          "footer": {
            "privacy": "Privacy policy",
            "terms": "Terms & conditions",
            "rights": "All rights reserved"
          },
          "sidebar": {
            "link": {
              "dashboard": "Dashboard",
              "profile": "Profile",
              "logout": "Log out"
            }
          }
        },
        "pagination": {
          "paginationDeets": {
            "showing": "Showing",
            "pagination": "{0} {1} to {2} of {3} {4}"
          }
        },
        "calendar": {
          "options": {
            "1": "Today",
            "2": "This week",
            "3": "Last week",
            "4": "This month",
            "5": "Last month",
            "6": "This year"
          },
          "month": {
            "0": "January",
            "1": "February",
            "2": "March",
            "3": "April",
            "4": "May",
            "5": "June",
            "6": "July",
            "7": "August",
            "8": "September",
            "9": "October",
            "10": "November",
            "11": "December"
          },
          "day": {
            "1": "Sun",
            "2": "Mon",
            "3": "Tue",
            "4": "Wed",
            "5": "Thu",
            "6": "Fri",
            "7": "Sat"
          },
          "buttons": {
            "cancel": "Cancel",
            "confirmation": "Ok"
          }
        },
        "imageUploadPreview": {
          "unsupported": "The image format is unsupported or the image can't displayed."
        },
        "content": {
          "contentPreviewModal": {
            "paging": "{0} of {1}"
          }
        }
      },
      "helpers": {
        "units": {
          "time": {
            "seconds": "{nb}s",
            "minutes": "{nb}m",
            "hours": "{nb}h",
            "days": "{nb}d",
            "months": "{nb}mo",
            "years": "{nb}y",
            "fewSeconds": "Few seconds",
            "endWord": {
              "left": "{text} left",
              "late": "{text} late"
            }
          }
        }
      }
    },
    "modules": {
      "account": {
        "login": {
          "meta": "Sign in",
          "h1": "Sign in",
          "text": {
            "account": "Don't have an account?",
            "signWith": "Or sign in with:"
          },
          "link": {
            "forgot": "Forgot password?",
            "signUp": "Sign up here"
          }
        },
        "logout": {
          "meta": "Log out",
          "h1": {
            "loggingOut": "Logging you out...",
            "loggedOut": "You have been logged out!"
          },
          "p": {
            "loggingOut": "We are logging you out from the system, please be patient...",
            "loggedOut": "You have been successfully logged out from the system."
          }
        }
      },
      "administrators": {
        "h1": "Administrators",
        "h3": "Permissions for the administrator: {name}",
        "setSuspended": {
          "title": "Administrator {id} - {name}",
          "flash": {
            "suspended": "The administrator has been suspended successfully.",
            "unsuspended": "The administrator has been unsuspended successfully."
          }
        },
        "create": {
          "h1": "Create administrator",
          "link": "Create administrator",
          "flash": {
            "create": "The administrator has been created successfully."
          }
        },
        "edit": {
          "link": "Edit",
          "h1": "Edit administrator",
          "flash": {
            "edit": "The administrator has been edited successfully."
          },
          "permissions": {
            "h1": "Edit administrator permissions",
            "link": "Edit permissions",
            "flash": {
              "add": "The permission has been added successfully.",
              "delete": "The permission has been deleted successfully."
            }
          }
        },
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "email": "Email"
        },
        "dataTable": {
          "noRecords": "No administrators found.",
          "legend": "administrator | administrators",
          "search": {
            "placeholder": "Search by first name/last name/email address"
          }
        }
      }
    }
  },
  "modules": {
    "company": {
      "h1": {
        "companyDetails": "Company details",
        "cancelModal": "Cancel as company - Job {id}",
        "collaboratorDetails": "Collaborator from company {0} id {1}"
      },
      "tabs": {
        "companyDetails": "Company details"
      },
      "edition": {
        "h1": "Edit company",
        "title": "Edit company",
        "flash": {
          "edit": "Company edited successfully.",
          "canceled": "The job has been canceled successfully."
        },
        "personalInformation": {
          "information": {
            "h3": "Personal information"
          },
          "address": {
            "h3": "Information address"
          }
        },
        "button": {
          "updateCompany": "Update company"
        },
        "textarea": {
          "message": {
            "cancelJob": {
              "placeholder": "Enter the reason why you are cancel the job."
            }
          },
          "validation": {
            "required": "This message is required.",
            "minStringChars": "The message must have at least {minStringChars} characters.",
            "maxStringChars": "The message must not exceed {maxStringChars} characters."
          }
        }
      },
      "summary": {
        "label": "Company {id} - {name}",
        "meta": {
          "title": "Company details"
        },
        "tabs": {
          "companyDetails": "Company details",
          "logs": "Logs"
        },
        "plan": {
          "payAsYouGo": "PAY-AS-YOU-GO",
          "label": "Plan",
          "button": "Manage plan"
        }
      },
      "logs": {
        "h2": "Logs",
        "columns": {
          "ip": "IP",
          "created": "Created",
          "name": "Admin",
          "message": "Message",
          "country": "Country",
          "state": "State",
          "city": "City",
          "proxy": "Proxy",
          "hosting": "Hosting",
          "collaborator": "Collaborator"
        },
        "noRecords": "No logs found for the company.",
        "legend": "company log | company logs",
        "company": "Company {id}"
      },
      "companies": {
        "h1": "Companies",
        "title": "Company",
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "email": "Email",
          "onboarding": "Onboarding",
          "campaigns": "Campaigns"
        },
        "onboarding": {
          "0": {
            "title": "Start"
          },
          "1": {
            "title": "Completed"
          }
        },
        "noRecords": "No companies found",
        "legend": "company | companies",
        "search": {
          "placeholder": "Search term"
        },
        "flash": {
          "suspended": "The company has been suspended successfully.",
          "unsuspended": "The company has been unsuspended successfully."
        },
        "plan": {
          "payAsYouGo": "PAY-AS-YOU-GO",
          "startup": "STARTUP",
          "brand": "BRAND",
          "agency": "AGENCY"
        },
        "filters": {
          "searchBy": {
            "email": "Search by email",
            "name": "Search by name",
            "all": "Search multi-criteria"
          }
        },
        "link": {
          "top": "See top companies"
        }
      },
      "buttons": {
        "setVip": "Set VIP",
        "unSetVip": "Remove VIP"
      },
      "modals": {
        "setVipModal": {
          "title": "Add company as VIP",
          "message": "Are you sure you want to add this company as VIP?"
        },
        "unSetVipModal": {
          "title": "Remove company as VIP",
          "message": "Are you sure you want to remove this company as VIP?"
        },
        "cancelModal": {
          "title": "Cancel as brand - Job {id}"
        }
      },
      "portfolioContent": {
        "label": "Show content in portfolios",
        "yes": "Yes",
        "no": "No",
        "switch": "Hide content",
        "success": "The content privacy has been updated."
      },
      "2fa": {
        "label": "Two-factor authentication on login",
        "enabled": "Enabled",
        "disabled": "Disabled",
        "success": {
          "enabled": "The two-factor authentication on login has been enabled.",
          "disabled": "The two-factor authentication on login has been disabled."
        }
      },
      "kyc": {
        "label": "Know Your Customer",
        "status": {
          "approved": "Approved",
          "rejected": "Rejected",
          "notAvailable": "Not available"
        },
        "button": {
          "approve": "Approve",
          "reject": "Reject"
        },
        "flash": {
          "approved": "The KYC has been approved for the company.",
          "rejected": "The KYC has been rejected for the company."
        }
      },
      "referral": {
        "title": "Referral",
        "label": "Referral URL"
      },
      "withdrawals": {
        "h1": "Withdrawals pending approval",
        "approval": {
          "h1": "Withdrawal approval",
          "allPending": "See all pending withdrawals",
          "details": {
            "company": "Company {id}",
            "amount": "Withdrawal amount",
            "flash": {
              "approve": "The withdrawal has been approved.",
              "deny": "The withdrawal has been denied."
            }
          },
          "tabs": {
            "companyPayments": {
              "title": "Company payments",
              "dataTable": {
                "columns": {
                  "id": "ID",
                  "created": "Created",
                  "description": "Description",
                  "paymentMethod": {
                    "column": "Payment method",
                    "credit_card": "Credit card",
                    "debit_card": "Debit card",
                    "wire_transfer": "Wire transfer",
                    "details": "{ccProvider} ending in {lastCardDigits} "
                  },
                  "amount": "Amount",
                  "status": "Status"
                },
                "legend": "payment | payments",
                "noRecords": "No payments found"
              }
            },
            "companyTransactions": {
              "title": "Company transactions",
              "dataTable": {
                "columns": {
                  "id": "ID",
                  "created": "Created",
                  "description": "Description",
                  "type": {
                    "column": "Type",
                    "credit": "Credit",
                    "debit": "Debit"
                  },
                  "amount": "Amount"
                },
                "legend": "transaction | transactions",
                "noRecords": "No transactions found"
              }
            }
          }
        }
      },
      "watermark": {
        "h1": "Watermarks pending approval",
        "approval": {
          "h1": "Watermark approval",
          "allPending": "See all pending watermarks"
        },
        "table": {
          "column": {
            "id": "Id",
            "companyId": "Company id"
          },
          "legend": "watermark | watermarks",
          "noRecords": "No watermarks found."
        }
      },
      "badges": {
        "vip": "VIP",
        "collaborator": "Collaborator brand id {id}"
      },
      "topCompanies": {
        "h1": "Top companies",
        "meta": {
          "title": "Top companies"
        },
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "email": "Email",
          "jobsCompleted": "Jobs completed",
          "jobsCompletedLast": "Jobs completed 30 days",
          "amountDeposited": "Amount deposited",
          "amountDepositedLast": "Amount deposited 30 days"
        },
        "noRecords": "No companies found.",
        "legend": "company | companies",
        "flash": {
          "suspended": "The company has been suspended successfully.",
          "unsuspended": "The company has been unsuspended successfully.",
          "softBanned": "The soft ban has been applied for the company.",
          "unSoftBanned": "The soft ban has been removed for the company."
        },
        "filters": {
          "sortBy": {
            "label": "Sort by",
            "options": {
              "amountDeposited": "Amount deposited",
              "amountDepositedLast": "Amount deposited 30 days",
              "jobsCompleted": "Jobs completed",
              "jobsCompletedLast": "Jobs completed 30 days"
            }
          }
        }
      }
    },
    "creator": {
      "h2": {
        "personalInformation": "Personal information"
      },
      "summary": {
        "h1": "Creator {id} - {name}",
        "meta": {
          "title": "Creator details"
        },
        "aboutMe": {
          "seeImage": "See image"
        },
        "certifications": "Certifications",
        "certification": {
          "notStarted": "Not started",
          "notProvided": "Not provided",
          "seeVideo": "See video",
          "seeImage": "See image",
          "columns": {
            "id": "ID",
            "name": "Name",
            "status": "Status",
            "content": "Content"
          },
          "status": {
            "notStarted": "Not started",
            "queued": {
              "tikTok": "Pending TikTok information",
              "tikTok_shop": "Pending TikTok Shop information",
              "instagram": "Pending Instagram information",
              "youtube": "Pending YouTube information",
              "amazon": "Pending Amazon information"
            },
            "pendingApproval": "Pending approval",
            "pendingVideos": "Pending 50 videos",
            "approved": "Approved",
            "denied": "Denied"
          },
          "flash": {
            "tiktok": {
              "approve": "The TikTok certification has been approved successfully.",
              "deny": "The TikTok certification has been denied successfully."
            },
            "tiktok_shop": {
              "approve": "The TikTok Shop certification has been approved successfully.",
              "deny": "The TikTok Shop certification has been denied successfully."
            },
            "instagram": {
              "approve": "The Instagram certification has been approved successfully.",
              "deny": "The Instagram certification has been denied successfully."
            },
            "youtube": {
              "approve": "The YouTube certification has been approved successfully.",
              "deny": "The YouTube certification has been denied successfully."
            },
            "amazon": {
              "approve": "The Amazon Shoppable Video certification has been approved successfully.",
              "deny": "The Amazon Shoppable Video certification has been denied successfully."
            }
          }
        },
        "ethnicity": {
          "button": "Update ethnicity",
          "modal": {
            "button": {
              "update": "Save"
            },
            "flash": {
              "update": "The ethnicity has been updated successfully."
            }
          }
        },
        "gender": {
          "button": "Update gender",
          "modal": {
            "button": {
              "update": "Save gender"
            },
            "flash": {
              "update": "The gender has been updated successfully."
            }
          }
        },
        "tabs": {
          "creatorDetails": "Creator details",
          "certifications": "Certifications",
          "logs": "Logs"
        }
      },
      "edition": {
        "title": "Edit creator",
        "h1": {
          "title": "Edit creator"
        },
        "personalInformation": {
          "information": {
            "h3": "Personal information"
          },
          "address": {
            "h3": "Address information"
          }
        },
        "button": {
          "updateCreator": "Update creator"
        },
        "textarea": {
          "message": {
            "cancelJob": {
              "placeholder": "Enter the reason why you are cancel the job."
            }
          },
          "validation": {
            "required": "This message is required.",
            "minStringChars": "The message must have at least {minStringChars} characters.",
            "maxStringChars": "The message must not exceed {maxStringChars} characters."
          }
        }
      },
      "modals": {
        "setProModal": {
          "title": "Add creator as PRO",
          "message": "Are you sure you want to add this creator as PRO?"
        },
        "unSetProModal": {
          "title": "Remove creator as PRO",
          "message": "Are you sure you want to remove this creator as PRO?"
        },
        "cancelModal": {
          "title": "Cancel as creator - Job {id}"
        }
      },
      "creators": {
        "h1": "Creators",
        "meta": {
          "title": "Creators"
        },
        "link": {
          "top": "See top creators"
        },
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "email": "Email",
          "onboarding": "Onboarding"
        },
        "onboarding": {
          "0": {
            "title": "Introduction"
          },
          "1": {
            "title": "Personal information"
          },
          "2": {
            "title": "About me"
          },
          "3": {
            "title": "Social networks"
          },
          "4": {
            "title": "Categories"
          },
          "5": {
            "title": "Sample photo"
          },
          "6": {
            "title": "Sample video"
          },
          "7": {
            "title": "Disclaimer"
          },
          "8": {
            "title": "Summary"
          },
          "9": {
            "title": "Application sent"
          },
          "10": {
            "title": "Application approved"
          }
        },
        "noRecords": "No creators found.",
        "legend": "creator | creators",
        "search": {
          "placeholder": "Search term"
        },
        "flash": {
          "suspended": "The creator has been suspended successfully.",
          "unsuspended": "The creator has been unsuspended successfully.",
          "softBanned": "The soft ban has been applied for the creator.",
          "unSoftBanned": "The soft ban has been removed for the creator.",
          "canceled": "The job has been canceled successfully."
        }
      },
      "topCreators": {
        "h1": "Top creators",
        "meta": {
          "title": "Top creators"
        },
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "email": "Email",
          "jobs": "Jobs",
          "jobsThirty": "Jobs 30 days",
          "total": "Total",
          "totalThirty": "Total 30 days",
          "earned": "Total earned",
          "earnedThirty": "Total earned 30 days",
          "reimbursed": "Total reimbursed",
          "reimbursedThirty": "Total reimbursed 30 days"
        },
        "noRecords": "No creators found.",
        "legend": "creator | creators",
        "flash": {
          "suspended": "The creator has been suspended successfully.",
          "unsuspended": "The creator has been unsuspended successfully.",
          "softBanned": "The soft ban has been applied for the creator.",
          "unSoftBanned": "The soft ban has been removed for the creator."
        },
        "filters": {
          "sortBy": {
            "label": "Sort by",
            "options": {
              "jobs": "Jobs",
              "jobsThirty": "Jobs 30 days",
              "total": "Total",
              "totalThirty": "Total 30 days",
              "earned": "Earned",
              "earnedThirty": "Earned 30 days",
              "reimbursed": "Reimbursed",
              "reimbursedThirty": "Reimbursed 30 days"
            }
          }
        }
      },
      "approval": {
        "h1": "Creator approval",
        "meta": {
          "title": "Creator approval"
        },
        "link": {
          "all": "See all pending creators",
          "image": "See image"
        },
        "flash": {
          "approve": "The creator has been approved.",
          "retry": "The creator has been notified that some information is missing/invalid.",
          "skip": "The creator approval has been postponed for 24 hours.",
          "ban": "The creator has been banned successfully."
        }
      },
      "pendingCreators": {
        "h1": "Creators pending approval",
        "meta": {
          "title": "Creators pending approval"
        },
        "columns": {
          "id": "ID",
          "name": "Name",
          "email": "Email",
          "created": "Created"
        },
        "noRecords": "No creators found.",
        "legend": "creator | creators",
        "flash": {
          "suspended": "The creator has been suspended successfully.",
          "unsuspended": "The creator has been unsuspended successfully.",
          "softBanned": "The soft ban has been applied for the creator.",
          "unSoftBanned": "The soft ban has been removed for the creator."
        }
      },
      "amazonInfluencerApproval": {
        "h1": "Amazon influencer approval",
        "meta": {
          "title": " influencer approval"
        },
        "link": {
          "all": "See all pending Amazon influencers",
          "image": "See image"
        },
        "flash": {
          "approve": "The Amazon influencer has been approved.",
          "deny": "The Amazon influencer has been declined."
        }
      },
      "pendingAmazonInfluencers": {
        "h1": "Amazon influencers pending approval",
        "meta": {
          "title": "Amazon influencers pending approval"
        },
        "columns": {
          "id": "ID",
          "name": "Name",
          "email": "Email",
          "amazon": "Amazon URL"
        },
        "noRecords": "No Amazon influencers found.",
        "legend": "Amazon influencer | Amazon influencers"
      },
      "portfolioReport": {
        "h1": "Creator portfolio report",
        "meta": {
          "title": "Creator portfolio report"
        },
        "flash": {
          "submit": "The content review has been done successfully.",
          "suspend": "The creator has been banned successfully."
        },
        "noContent": {
          "title": "No content to approve for the creator {id}."
        }
      },
      "portfolioReports": {
        "h1": "Creator portfolio reports",
        "meta": {
          "title": "Creator portfolio reports"
        },
        "columns": {
          "id": "ID",
          "name": "Name",
          "issue": "Issue",
          "message": "Message",
          "date": "Date"
        },
        "noRecords": "No creators found.",
        "legend": "creator | creators",
        "link": {
          "portfolio": "Go to portfolio",
          "all": "See all pending creator portfolios"
        },
        "flash": {}
      },
      "logs": {
        "h2": "Logs",
        "columns": {
          "ip": "IP",
          "created": "Created",
          "name": "Admin",
          "message": "Message",
          "country": "Country",
          "state": "State",
          "city": "City",
          "proxy": "Proxy",
          "hosting": "Hosting"
        },
        "noRecords": "No logs found for the creator.",
        "legend": "creator log | creator logs",
        "creator": "Creator {id}"
      },
      "paymentsApproval": {
        "h1": "Creators payments pending approval",
        "meta": {
          "title": "Payments pending approval"
        },
        "columns": {
          "id": "ID",
          "name": "Name"
        },
        "noRecords": "No payments pending approval found.",
        "legend": "payment pending approval | payments pending approval"
      },
      "paymentApproval": {
        "h1": "Creator payment pending approval",
        "link": {
          "all": "See all payments pending",
          "certifications": "See certifications"
        },
        "button": {
          "approve": "Approve",
          "deny": "Deny"
        },
        "details": {
          "earnings": "Earnings",
          "reimbursements": "Reimbursements",
          "flash": {
            "approve": "The payment has been approved.",
            "deny": "The payment has been denied."
          }
        },
        "tabs": {
          "jobs": {
            "title": "Creator jobs",
            "dataTable": {
              "columns": {
                "id": "ID",
                "earning": "Earning",
                "productName": "Product Name",
                "type": "Type"
              },
              "legend": "job | jobs",
              "noRecords": "No jobs found"
            }
          },
          "transactions": {
            "title": "Creator transactions",
            "dataTable": {
              "columns": {
                "id": "ID",
                "created": "Created",
                "description": "Description",
                "amount": "Amount",
                "type": {
                  "column": "Type",
                  "credit": "Credit",
                  "debit": "Debit"
                }
              },
              "legend": "transaction | transactions",
              "noRecords": "No transactions found"
            }
          }
        }
      },
      "filters": {
        "searchBy": {
          "email": "Search by email",
          "name": "Search by name",
          "all": "Search multi-criteria"
        },
        "tooltip": {
          "active": "Creators with activity in the last 30 days",
          "occasionally": "Creators with activity in any time",
          "applied": "Creators who only applied for a job"
        }
      },
      "referral": {
        "title": "Referral",
        "label": "Referral URL"
      },
      "2fa": {
        "label": "Two-factor authentication on login",
        "enabled": "Enabled",
        "disabled": "Disabled",
        "success": {
          "enabled": "The two-factor authentication on login has been enabled.",
          "disabled": "The two-factor authentication on login has been disabled."
        }
      }
    },
    "certification": {
      "title": "Certification",
      "certifications": {
        "h1": "Certifications",
        "meta": {
          "title": "Certifications"
        },
        "creatorId": "Creator {id}",
        "certification": {
          "label": {
            "notProvided": "Not provided"
          }
        },
        "pendingList": {
          "title": "Certifications pending approval",
          "datatable": {
            "columns": {
              "id": "ID",
              "creator": "Creator id",
              "certification": "Certification"
            },
            "legend": "certification | certifications",
            "noRecords": "No campaigns found",
            "process": "Process"
          }
        },
        "allPending": "See all certifications"
      },
      "link": {
        "image": "See image",
        "video": "See video"
      },
      "1": {
        "title": "How-to video approval",
        "flash": {
          "approve": "The video how to certification for the creator has been approved successfully.",
          "delay": "The video how to certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "2": {
        "title": "Unboxing video approval",
        "flash": {
          "approve": "The video unboxing certification for the creator has been approved successfully.",
          "delay": "The video unboxing certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "3": {
        "title": "Product review approval",
        "flash": {
          "approve": "The video product review certification for the creator has been approved successfully.",
          "delay": "The video product review certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "4": {
        "title": "Product demo approval",
        "flash": {
          "approve": "The video product certification for the creator has been approved successfully.",
          "delay": "The video product certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "5": {
        "title": "Lifestyle shot approval",
        "flash": {
          "approve": "The image product only review certification for the creator has been approved successfully.",
          "delay": "The image product only review certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "6": {
        "title": "Selfie product approval",
        "flash": {
          "approve": "The image certification for the creator has been approved successfully.",
          "delay": "The image certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "7": {
        "title": "Product demo approval",
        "flash": {
          "approve": "The image product demo review certification for the creator has been approved successfully.",
          "delay": "The image product demo review certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      },
      "8": {
        "title": "Product only approval",
        "flash": {
          "approve": "The image product only review certification for the creator has been approved successfully.",
          "delay": "The image product only review certification for the creator has been delayed successfully.",
          "retry": "The creator has been notified successfully that some information is missing/invalid."
        }
      }
    },
    "campaign": {
      "h1": "Campaign details",
      "tabs": {
        "campaignDetails": "Campaign details"
      },
      "pricePerJob": "Price per job",
      "details": {
        "sidebar": {
          "campaignStatus": "Campaign status",
          "statusCompleted": "Completed",
          "statusInProgress": "In progress",
          "needToFind": "Need to find",
          "totalJobs": "Total jobs",
          "totalProjectBudget": "Total project budget",
          "jobsAvailable": "Jobs available",
          "jobsAvailableCount": "{available} of {total}",
          "platformFee": "Platform Fee",
          "moneySpent": "Money spent",
          "creators": "{count} creator | {count} creators"
        }
      },
      "deliveryNote": {
        "1": "You have to send the product to the creator",
        "2": "The creator buys the product, you refund the cost"
      },
      "creatorApproval": {
        "manual": "Manual approval",
        "automatic": "Automatic approval"
      },
      "deliveryType": {
        "1": {
          "label": "Shipped by me",
          "badge": "You have to send the product to the creator"
        },
        "2": {
          "label": "Reimbursement",
          "badge": "Creators buy the product, you refund the cost"
        }
      },
      "productType": {
        "digital": "Digital",
        "physical": "Physical"
      },
      "digitalProduct": {
        "title": "Digital product",
        "legend": "Online service without delivery"
      },
      "summary": {
        "meta": {
          "title": "Summary"
        },
        "h1": "Campaign {id}",
        "contentType": {
          "contentFormat": {
            "sizes": {
              "portrait": "Portrait",
              "landscape": "Landscape",
              "square": "Square",
              "any": "Any"
            },
            "duration": "{time} seconds",
            "note": "(portrait, landscape or square)"
          }
        },
        "link": {
          "product": "Product link"
        },
        "earningsPerJob": "Earnings per job",
        "tabs": {
          "creators": "Creators",
          "delivery": "Delivery",
          "campaignDetails": "Campaign details",
          "costBreakdown": "Cost breakdown",
          "logs": "Logs"
        }
      },
      "campaigns": {
        "meta": {
          "title": "Campaigns"
        },
        "h1": "Campaigns",
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "type": "Type",
          "company": "Company",
          "brand": "Brand"
        },
        "noRecords": "No campaigns found.",
        "legend": "campaign | campaigns",
        "search": {
          "placeholder": "Search by product title/brand name/keywords ({minStringChars} character minimum)"
        },
        "button": {
          "see": "Go to campaign",
          "seeCompany": "Go to company"
        }
      },
      "pendingCampaigns": {
        "meta": {
          "title": "Campaigns pending approval"
        },
        "h1": "Campaigns pending approval",
        "columns": {
          "id": "ID",
          "created": "Created",
          "name": "Name",
          "type": "Type",
          "company": "Company",
          "brand": "Brand"
        },
        "dataTable": {
          "legend": "campaign | campaigns",
          "noRecords": "No campaigns found",
          "approve": "Go to approval"
        },
        "linkCompany": "{id} - {email}"
      },
      "approval": {
        "h1": "Campaign approval {id}",
        "title": "Campaign approval",
        "meta": "Campaign approval",
        "company": "Company {id}",
        "creator": "Creator {id}",
        "link": {
          "all": "See all pending campaigns"
        },
        "reject": {
          "flash": {
            "reject": "The campaign has been rejected successfully."
          }
        },
        "approve": {
          "flash": {
            "approve": "The campaign has been approved successfully."
          }
        },
        "validation": {
          "minStringChars": "The minimum number of characters required is {minStringChars}.",
          "maxStringChars": "The maximum number of characters allowed is {maxStringChars}."
        }
      },
      "information": {
        "costBreakdown": {
          "costsOfCampaign": {
            "title": "Campaign",
            "pricePerImage": "Price per image",
            "pricePerVideo": "Price per video",
            "productCostReimbursement": "Product cost reimbursement",
            "pricePerJob": "Price per job",
            "numberOfJobs": "Number of jobs",
            "x": "× {quantity}",
            "subtotalCostOfCampaign": "Subtotal cost of campaign",
            "platformFee": "Platform fee",
            "subtotalCampaignCost": "Subtotal campaign cost",
            "refunds": "Refunds"
          },
          "costsAdditionalExpenses": {
            "title": "Additional expenses",
            "averageCostPerExtraImage": "Average cost per extra image",
            "averageCostPerExtraVideo": "Average cost per extra video",
            "numberOfImages": "Number of images",
            "numberOfVideos": "Number of videos",
            "x": "× {quantity}",
            "subtotalExtraContentCost": "Subtotal extra content cost",
            "tips": "Tips",
            "subtotal": "Subtotal",
            "platformFee": "Platform fee",
            "additionalExpenses": "Additional expenses",
            "refunds": "Refunds"
          },
          "total": "Total"
        },
        "dataTable": {
          "creator": "Creator",
          "timer": "Timer",
          "action": "Action",
          "noData": "No work available to show",
          "trackNumber": "Track number",
          "legend": "job | jobs"
        },
        "creator": {
          "GetFor": "Get for {amount}",
          "datatable": {
            "noData": "No creators found."
          }
        },
        "form": {
          "validation": {
            "alphanumeric": "Only alphanumeric characters are allowed.",
            "trackingNumber": {
              "max": "The tracking number must be at most 100 characters.",
              "min": "The tracking number must have at least 3 characters.",
              "required": "The tracking number is required."
            },
            "carrierName": {
              "max": "The shipping carrier must be at most 30 characters.",
              "min": "The shipping carrier name must have at least 3 characters.",
              "required": "The shipping carrier name is required."
            }
          },
          "inputs": {
            "select": {
              "placeholder": "All statuses",
              "pendingShipment": "Requiring delivery",
              "productSent": "Out for delivery",
              "contentCreation": "Content creation",
              "contentReady": "Content ready",
              "canceled": "Canceled",
              "completed": "Completed",
              "shipped": "Shipped",
              "delivered": "Delivered",
              "closed": "Closed"
            }
          }
        },
        "campaignStatus": {
          "active": "Active",
          "completed": "Completed",
          "created": "Pending approval",
          "deleted": "Deleted",
          "paused": "Paused",
          "rejected": "Declined"
        },
        "campaignType": {
          "flat_fee_bundle": "Blast",
          "classic_percentage": "Сlassic"
        }
      },
      "logs": {
        "h2": "Logs",
        "columns": {
          "ip": "IP",
          "created": "Created",
          "name": "Admin",
          "message": "Message",
          "country": "Country",
          "state": "State",
          "city": "City",
          "proxy": "Proxy",
          "hosting": "Hosting"
        },
        "noRecords": "No campaign logs found.",
        "legend": "campaign log | campaign logs",
        "campaign": "Campaign {id}"
      }
    },
    "recurringCampaigns": {
      "meta": {
        "title": "Recurring campaigns"
      },
      "h1": "Recurring campaigns",
      "type": {
        "instagram": "Instagram",
        "tiktok": "TikTok",
        "tiktok_shop": "TikTok Shop",
        "tiktok_instagram": "TikTok + Instagram"
      },
      "campaigns": {
        "columns": {
          "id": "ID",
          "created": "Created",
          "updated": "Updated",
          "name": "Name",
          "type": "Type",
          "company": "Company",
          "brand": "Brand",
          "n_of_creators": "Number of creators",
          "nr_new_content": "New content",
          "total_cycle_charge_amount": "Total amount charged monthly"
        },
        "noRecords": "No campaigns found.",
        "legend": "campaign | campaigns",
        "search": {
          "placeholder": "Search by product title/brand name/keywords ({minStringChars} character minimum)"
        },
        "button": {
          "see": "Go to campaign",
          "seeCompany": "Go to company"
        }
      },
      "info": {
        "is_archivable": "Archivable",
        "shipping_required": "Shipping",
        "frequency": {
          "weekly": "Weekly",
          "monthly": "Monthly",
          "slot": {
            "daily": "Daily",
            "every_other_day": "Every other day",
            "every_third_day": "Every third day",
            "twice_a_day": "Twice a day",
            "quantity": "Slot quantity: {num}"
          }
        },
        "cycle_charge_amount": "Monthly pay: {amount}"
      },
      "summary": {
        "h1": "Recurring campaign {id}",
        "price": "Monthly pay",
        "statusOption": {
          "negotiation": "Negotiation",
          "active": "Active",
          "paused": "Paused",
          "paymentFailed": "Payment failed",
          "canceled": "Canceled"
        }
      }
    },
    "content": {
      "contentApproval": {
        "h1": "Content approval",
        "h2": "Content",
        "content": "Content",
        "preview": {
          "image": {
            "externalLink": "See image"
          },
          "video": {
            "externalLink": "See video",
            "mark": {
              "tooltip": "Add to inspiration",
              "flash": {
                "success": "The content has been marked as inspiring and sent to the Slack channel jb-inspiration.",
                "error": "An error occurred when marking the content as inspiring."
              }
            }
          }
        },
        "link": {
          "all": "See all pending contents"
        },
        "noContent": {
          "title": "No content to approve for the job {id}."
        },
        "contentRejected": {
          "title": "The brand has rejected the initial content from the creator",
          "text": "Here is the reason provided: {reason}"
        },
        "flash": {
          "submit": "The content review has been done successfully.",
          "suspend": "The creator has been banned successfully."
        }
      },
      "pendingContents": {
        "meta": {
          "title": "Contents pending approval"
        },
        "h1": "Contents pending approval",
        "columns": {
          "id": "ID",
          "contentPending": "Content pending",
          "creatorName": "Creator name",
          "companyName": "Company name",
          "campaignName": "Campaign name",
          "certification": "Certification"
        },
        "dataTable": {
          "legend": "content | contents",
          "noRecords": "No contents found",
          "approve": "Go to approval content"
        }
      },
      "purchasedContents": {
        "columns": {
          "id": "ID",
          "contentPending": "Content pending",
          "creatorName": "Creator name",
          "companyName": "Company name",
          "campaignName": "Campaign name",
          "certification": "Certification"
        },
        "dataTable": {
          "legend": "content | contents",
          "noRecords": "No contents found",
          "approve": "Go to approval content"
        }
      },
      "video": {
        "title": "Video {id}",
        "information": {
          "videoResolution": "Best video resolution",
          "conversionType": "Conversion type",
          "duration": "Duration",
          "fps": "Fps",
          "orientation": "Orientation",
          "status": "Status",
          "processAgain": "Process again"
        },
        "process": {
          "rotate": {
            "label": "Rotate",
            "left": "Left",
            "right": "Right",
            "180": "180"
          },
          "flip": {
            "label": "Flip",
            "vertical": "Vertical",
            "horizontal": "Horizontal"
          },
          "successMessage": "The video processing order has been sent, in a few minutes it will be updated."
        },
        "4k": {
          "title": "4k video",
          "download": "Download 4k video"
        },
        "fullHd": {
          "title": "Full HD video",
          "download": "Download Full HD video"
        },
        "hd": {
          "title": "HD video",
          "download": "Download HD video"
        },
        "custom": {
          "title": "Custom video",
          "download": "Download Custom video"
        }
      },
      "contentRejected": {
        "title": "Content rejected"
      }
    },
    "dashboard": {
      "h1": "Dashboard",
      "cards": {
        "creatorApproval": "Creators pending approval",
        "creatorPortfolios": "Creator portfolio reports",
        "campaignApproval": "Campaigns pending approval",
        "contentApproval": "Job contents pending approval",
        "lateJobs": "Late jobs",
        "jobMediations": "Job mediations",
        "tikTokAds": "TikTok ads pending approval",
        "amazonInfluencers": "Amazon influencers pending approval",
        "companyWithdrawals": "Withdrawals pending approval",
        "creatorPaymentsApproval": "Creators payments pending approval",
        "watermarksApproval": "Watermarks pending approval"
      }
    },
    "job": {
      "summary": {
        "meta": "Job details",
        "h1": {
          "job": "Job {id}"
        },
        "jobDetails": {
          "title": "Job details",
          "label": {
            "creatorName": "Creator name",
            "companyName": "Company name",
            "certification": "Certification"
          }
        },
        "content": {
          "title": "Job content",
          "subtitle": "Content",
          "rejectReason": " Reject reason",
          "status": {
            "bought": "Content purchased",
            "approval": "Content under review",
            "accepted": "Content reviewed",
            "denied": "Content declined"
          },
          "badge": {
            "bought": "Purchased",
            "denied": "Declined"
          },
          "empty": "No content available.",
          "alt": "Content {id}",
          "video": {
            "processing": "Processing video..."
          }
        },
        "product": {
          "label": {
            "earnings": "Earnings",
            "link": "Product link"
          },
          "badge": {
            "free": "Free product"
          },
          "shipment": {
            "free": "Will be shipped to you",
            "reimbursement": "Buy and get reimbursed"
          }
        },
        "tabs": {
          "jobContent": "Job content",
          "purchasedJobContent": "Purchased content",
          "jobDetails": "Job details",
          "logs": "Logs",
          "chat": "Chat"
        },
        "button": {
          "cancelAsCompany": "Cancel as brand",
          "cancelAsCreator": "Cancel as creator",
          "approval": "Go to approval"
        },
        "chat": {
          "noMessages": "No messages yet!"
        },
        "review": {
          "title": "Job review",
          "form": {
            "ratingLabel": "Edit rating",
            "textareaLabel": "Edit review",
            "buttonSubmit": "Submit",
            "validation": {
              "min": "The input must be greater than or equal to {min}.",
              "max": "The input must be less than or equal to {max}.",
              "required": "The input is required.",
              "valid": "You must input a valid number"
            }
          },
          "status": {
            "success": "The rating and review has been updated."
          }
        }
      },
      "jobs": {
        "h1": "Jobs",
        "price": "Price/job: {amount}",
        "#": "# of jobs: {amount}",
        "total": "Total budget: {amount}",
        "columns": {
          "id": "ID",
          "productName": "Product",
          "type": "Type",
          "creatorName": "Creator",
          "companyName": "Company",
          "deliveryType": "Delivery type",
          "status": "Status"
        },
        "noRecords": "No jobs found.",
        "legend": "job | jobs",
        "search": {
          "placeholder": "Search by product name/creator name ({minStringChars} character minimum)"
        },
        "button": {
          "goToJob": "Go to job"
        }
      },
      "recurringJobs": {
        "h1": "Recurring Jobs",
        "columns": {
          "id": "ID",
          "productName": "Product",
          "type": "Type",
          "creatorName": "Creator",
          "companyName": "Company",
          "deliveryType": "Delivery type",
          "status": "Status",
          "recurringCampaign": "Campaign"
        },
        "noRecords": "No jobs found.",
        "legend": "job | jobs",
        "search": {
          "placeholder": "Search by product name/creator name ({minStringChars} character minimum)"
        },
        "status": {
          "contractStatus": {
            "draft": "Draft",
            "negotiation": "Negotiation",
            "active": "Active",
            "payment_failed": "Payment failed",
            "canceled": "Canceled",
            "paused": "Paused"
          },
          "contractNegotiationStatus": {
            "no_offer": "No offer",
            "company_offer": "Company offer",
            "creator_counteroffer": "Creator counter offer",
            "company_accepted": "Company accepted",
            "creator_accepted": "Creator accepted",
            "company_rejected": "Company rejected",
            "creator_rejected": "Creator rejected",
            "payment_completed": "Payment completed",
            "payment_failed": "Payment failed",
            "pending_shipment_tracking_code": "Pending shipment tracking code",
            "pending_shipment_delivery": "Pending shipment delivery",
            "completed": "Completed"
          }
        },
        "summary": {
          "h1": "Recurring job: {id}",
          "meta": "Recurring job",
          "content": {
            "status": {
              "approved": "Approved",
              "completed": "Completed",
              "pending_approval": "Pending approval",
              "rejected": "Rejected"
            },
            "videoCount": "Video count",
            "dateUpdated": "Date updated",
            "dateFirstSlot": "Date first slot",
            "dateLastSlot": "Date last slot",
            "dateNextCharge": "Date next charge",
            "negotiationStatus": "Negotiation status",
            "shippingCarrier": "Shipping carrier",
            "shipmentTrackingNr": "Shipment tracking code",
            "nrContentCompleted": "Content completed",
            "postUrl": "Post url",
            "dateSlot": "Slot date",
            "sparkCode": "Spark code",
            "dateCreated": "Date created",
            "messageType": "{type} message"
          }
        }
      },
      "lateJobs": {
        "h1": "Late jobs",
        "price": "Price/job: {amount}",
        "#": "# of jobs: {amount}",
        "total": "Total budget: {amount}",
        "columns": {
          "id": "ID",
          "productName": "Product",
          "creatorName": "Creator",
          "companyName": "Company",
          "deliveryType": "Delivery type",
          "status": "Status",
          "late": "Time late"
        },
        "noRecords": "No late jobs found.",
        "legend": "late job | late jobs",
        "search": {
          "placeholder": "Search by product name/creator name ({minStringChars} character minimum)"
        },
        "button": {
          "goToJob": "Go to job"
        }
      },
      "jobTimeLine": {
        "title": "Job timeline",
        "status": {
          "jobStarted": "Job Started",
          "productSent": "Product sent",
          "productDelivered": "Product delivered",
          "contentUploaded": "Content Uploaded",
          "canceled": "Canceled",
          "jobCompleted": "Job completed",
          "pendingApproval": "Pending"
        }
      },
      "jobStatus": {
        "creator_accepted": "Started",
        "purchased_sent": {
          "typePurchasedDelivery": {
            "1": "Product sent",
            "2": "Product purchased"
          }
        },
        "product_received": "Product received",
        "content_delivered": "Content pending review",
        "completed": "Get paid",
        "content_admin_approved": "Content ready",
        "canceled": "Canceled",
        "finished": "Closed",
        "content_accepted": "Content accepted",
        "creator_waiting_for_publish_date": "Video posted"
      },
      "status": {
        "creator_accepted": {
          "shipIt": {
            "late": "Late to send product",
            "left": "Left to send product"
          },
          "reimbursement": {
            "late": "Late to order the product",
            "left": "Left to order the product"
          }
        },
        "purchasedSent": {
          "arriving": "Late to receive product",
          "awaiting": "Awaiting"
        },
        "product_received": {
          "late": "Late to get content",
          "left": "Left to get content"
        },
        "content_admin_approved": {
          "late": "Late to accept content",
          "left": "Left to accept content"
        }
      },
      "deliveryType": {
        "1": {
          "type": "Free product"
        },
        "2": {
          "type": "Reimbursement"
        }
      },
      "jobMediations": {
        "h1": "Job mediations",
        "meta": {
          "title": "Job mediations"
        },
        "columns": {
          "id": "ID",
          "productName": "Product",
          "creatorName": "Creator",
          "deliveryType": "Delivery type",
          "status": "Status"
        },
        "button": {
          "goMediation": "Go to mediation"
        }
      },
      "jobMediation": {
        "h1": "Job {id} - Job mediation",
        "meta": {
          "title": "Job mediation"
        },
        "link": {
          "all": "See all job mediations"
        },
        "flash": {
          "cancelMediation": "The job mediation has been resolved."
        },
        "form": {
          "header": {
            "title": "Job {id}"
          },
          "button": {
            "refund": "Refund"
          },
          "input": {
            "percentageRefund": "Enter the percentage to refund to the company"
          },
          "label": {
            "percentageRefund": "Percentage refund to the company"
          },
          "quantity": {
            "validation": {
              "min": "The percentage of refund must be greater than or equal to {minPercentage}.",
              "max": "The percentage of refund must be less than or equal to {maxPercentage}.",
              "integer": "The percentage number must not contain decimals."
            }
          }
        }
      },
      "logs": {
        "h2": "Logs",
        "columns": {
          "ip": "IP",
          "created": "Created",
          "name": "Admin",
          "message": "Message",
          "country": "Country",
          "state": "State",
          "city": "City",
          "proxy": "Proxy",
          "hosting": "Hosting"
        },
        "noRecords": "No job logs found.",
        "legend": "job log | job logs",
        "job": "Job {id}"
      }
    },
    "wireTransfers": {
      "h1": "Wire transfers",
      "columns": {
        "id": "ID",
        "created": "Created",
        "name": "Company name",
        "description": "Description",
        "status": "Status",
        "code": "Code",
        "amount": "Amount"
      },
      "textarea": {
        "code": {
          "placeholder": "Enter the wire transfer code."
        },
        "validation": {
          "required": "The code is required.",
          "minStringChars": "The code must have at least {minStringChars} characters.",
          "regexp": "The code must contain uppercase letters and numbers."
        }
      },
      "noRecords": "No wire transfers found.",
      "legend": "wire transfer | wire transfers",
      "edit": {
        "h1": "Edit transaction #{id}",
        "flash": {
          "edit": "Transaction edited successfully"
        }
      }
    },
    "video": {
      "h1": "Videos",
      "columns": {
        "id": "ID",
        "created": "Created",
        "duration": "Duration",
        "status": "Status",
        "error_message": "Error"
      },
      "status": {
        "processed": "Processed",
        "queued": "Queued",
        "failed": "Failed"
      },
      "noRecords": "No videos found.",
      "legend": "video | videos"
    },
    "wallet": {
      "type": {
        "credit": "Credit",
        "debit": "Debit"
      },
      "text": {
        "currentBalance": "Current balance",
        "notAvailable": "N/A"
      },
      "link": {
        "manageWallet": "Manage wallet"
      },
      "columns": {
        "logs": {
          "id": "ID",
          "created": "Created",
          "description": "Description",
          "type": "Type",
          "amount": "Amount"
        }
      },
      "legend": "transaction | transactions",
      "noRecords": "No transactions found",
      "form": {
        "amount": {
          "validation": {
            "min": "The amount must be greater than or equal to ${min}.",
            "max": "The amount must be less than or equal to ${max}.",
            "required": "The amount is required."
          }
        },
        "key": {
          "validation": {
            "required": "The key is required."
          }
        },
        "description": {
          "validation": {
            "minStringChars": "The description must have at least {minStringChars} characters.",
            "maxStringChars": "The description must not exceed {maxStringChars} characters.",
            "required": "The description is required."
          }
        }
      },
      "creator": {
        "h1": "Creator wallet {id} - {name}",
        "text": {
          "addFunds": "Add funds to creator ID {id}",
          "removeFunds": "Remove funds to creator ID {id}"
        },
        "meta": {
          "title": "Creator wallet"
        }
      },
      "company": {
        "h1": "Company wallet {id} - {name}",
        "text": {
          "addFunds": "Add funds to company {id}",
          "removeFunds": "Remove funds to company {id}"
        },
        "meta": {
          "title": "Company wallet"
        },
        "flash": {
          "success": "The funds have been added to the company wallet."
        }
      }
    },
    "tiktokAds": {
      "h1": "TikTok ads center",
      "columns": {
        "adName": "Ad name",
        "product": "Product",
        "status": "Status",
        "dailyBudget": "Daily budget",
        "impressions": "Impressions",
        "reach": "Reach",
        "clicks": "Clicks",
        "cpc": "CPC",
        "ctr": "CTR",
        "cpm": "CPM",
        "spend": "Total spent"
      },
      "noRecords": "No Ads found",
      "legend": "ad | ads",
      "footer": {
        "totalAds": "Total {count} ad | Total {count} ads"
      },
      "status": {
        "draft": "Draft",
        "adminPendingApproval": "Pending admin approval",
        "pendingCreation": "Pending creation",
        "paymentFailed": "Payment failed",
        "pendingTiktokApproval": "Pending TikTok approval",
        "requestedRefund": "Refund requested",
        "refundProcessed": "Refund processed",
        "paused": "Paused",
        "delivering": "Delivering",
        "completed": "Completed",
        "rejected": "Rejected by TikTok",
        "canceled": "Canceled",
        "scheduled": "Scheduled",
        "notExist": "Not exists"
      },
      "detailsSidebar": {
        "title": "TikTok Ad details"
      },
      "adStats": {
        "menu": {
          "job": "Go to job",
          "campaign": "Go to campaign",
          "company": "Go to company"
        },
        "label": {
          "dailyBudget": "Daily budget",
          "totalBudget": "Total budget",
          "totalSpent": "Amount Spent",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "spent": "Amount spent",
          "cpc": "CPC",
          "cpm": "CPM",
          "ctr": "CTR"
        }
      },
      "adSummary": {
        "title": "Ad summary",
        "link": "Link to post",
        "website": "Promoted website",
        "cta": "Call to action",
        "schedule": "Schedule",
        "location": "Location",
        "gender": "Gender",
        "age": "Age",
        "device": "Device",
        "interests": "Interests",
        "hashtags": "Hashtag interactions"
      },
      "audience": {
        "meta": "Boost audience",
        "step": "Audience",
        "title": "Audience",
        "basic": {
          "title": "Basic",
          "subtitle": "We will automatically find an audience"
        },
        "advance": {
          "title": "Advanced",
          "subtitle": "You can customize the audience yourself"
        },
        "gender": {
          "label": "Gender",
          "GENDER_UNLIMITED": "All",
          "GENDER_MALE": "Male",
          "GENDER_FEMALE": "Female"
        },
        "age": {
          "label": "Age",
          "ALL_AGES": "All",
          "AGE_13_17": "13-17",
          "AGE_18_24": "18-24",
          "AGE_25_34": "25-34",
          "AGE_35_44": "35-44",
          "AGE_45_54": "45-54",
          "AGE_55_100": "55+",
          "validation": {
            "required": "You must select at least one option"
          }
        },
        "device": {
          "label": "Device",
          "ALL": "All",
          "ANDROID": "Android",
          "IOS": "iOS",
          "validation": {
            "required": "You must select at least one option"
          }
        }
      }
    },
    "pendingTikTokAds": {
      "h1": "TikTok Ads pending approval",
      "columns": {
        "adName": "Ad name",
        "product": "Product",
        "status": "Status",
        "dailyBudget": "Daily budget"
      },
      "noRecords": "No Ads found",
      "legend": "ad | ads",
      "footer": {
        "totalAds": "Total {count} ad | Total {count} ads"
      }
    },
    "tikTokAdApproval": {
      "h1": "TikTok ad approval {id}",
      "link": {
        "all": "See all pending TikTok ads"
      },
      "flash": {
        "reject": "The TikTok ad has been rejected successfully.",
        "approve": "The TikTok ad has been approved successfully."
      },
      "tip": {
        "title": "Make sure the TikTok ad follows the TikTok Advertising Policies",
        "message": "The TikTok Advertising Policies are available here: {0}."
      }
    },
    "coupons": {
      "title": "Blast coupons",
      "button": "Create coupon",
      "table": {
        "searchPlaceholder": "Search coupon",
        "noRecords": "No coupons found",
        "legend": "Coupon | Coupons",
        "columns": {
          "id": "ID",
          "created": "Created",
          "code": "Code",
          "date": "Date",
          "restriction": "Usage restriction",
          "discount": "Discount",
          "type": "Type",
          "size": "Size",
          "usage": {
            "title": "Usage",
            "modalTitle": "Usage details",
            "table": {
              "dateUsed": "Date used",
              "company": "View company",
              "campaign": "View campaign"
            }
          }
        },
        "restriction": {
          "one_time": "One time",
          "once_per_user": "Once per user",
          "unlimited": "Unlimited",
          "only_new_users": "Only new users"
        },
        "status": {
          "active": "Active",
          "ended": "Ended"
        }
      },
      "create": {
        "meta": "Create coupon",
        "title": "Create coupon",
        "form": {
          "code": {
            "label": "Code",
            "placeholder": "Coupon code",
            "validation": {
              "required": "The coupon code is required",
              "max": "The coupon code must be less or equal to 255 characters.",
              "min": "The coupon code must have at least 6 characters.",
              "format": "The coupon code can not have spaces."
            }
          },
          "startDate": {
            "title": "Start date",
            "validation": {
              "valid": "You must select a valid date",
              "required": "You must select a starting date",
              "min": "The start date can't be lower than today"
            }
          },
          "endDate": {
            "title": "End date",
            "validation": {
              "valid": "You must select a valid date",
              "required": "You must select an ending date",
              "min": "The end date can't be lower than start date"
            }
          },
          "usage": {
            "title": "Usage restriction",
            "oneTime": "One time",
            "oncePerUser": "Once per user",
            "unlimited": "Unlimited",
            "onlyNewUsers": "Only new users"
          },
          "discount": {
            "title": "Discount percentage",
            "validation": {
              "valid": "You must set a valid discount percentage",
              "min": "The discount percentage must be at least 1%",
              "max": "The maximum discount percentage must be less or equal to 100%"
            }
          },
          "jobTypes": {
            "title": "Job types",
            "select": "Select job type",
            "selectAll": "Select all",
            "selected": "{nb} selected",
            "validation": {
              "required": "You must select at least one job type"
            }
          },
          "bundleSize": {
            "title": "Bundle sizes",
            "select": "Select bundle size",
            "selectAll": "Select all",
            "selected": "{nb} selected",
            "validation": {
              "required": "You must select at least one bundle size"
            }
          },
          "marketplaces": {
            "title": "Marketplaces",
            "us": "United States",
            "ca": "Canada",
            "uk": "United Kingdom",
            "au": "Australia",
            "validation": {
              "required": "You must select at least one marketplace"
            }
          }
        },
        "created": "The coupon has been created successfully."
      },
      "edit": {
        "meta": "Edit coupon",
        "title": "Edit coupon",
        "edited": "The coupon has been edited successfully."
      },
      "summary": {
        "meta": "Coupon summary",
        "title": "Coupon {code}",
        "detailsTab": "Details",
        "logsTab": "Logs",
        "details": {
          "tab": "Details",
          "title": "Coupon details",
          "id": "ID",
          "code": "Coupon code",
          "startDate": "Start Date",
          "endDate": "End Date",
          "discount": "Discount",
          "usage": "Usage restriction",
          "jobType": "Job types",
          "bundles": "Bundle sizes"
        },
        "edit": "Edit coupon"
      },
      "logs": {
        "title": "Logs",
        "columns": {
          "ip": "IP",
          "created": "Created",
          "name": "Admin",
          "message": "Message",
          "country": "Country",
          "state": "State",
          "city": "City",
          "proxy": "Proxy",
          "hosting": "Hosting"
        },
        "noRecords": "No logs found for the coupon.",
        "legend": "coupon log | coupon logs",
        "company": "Coupon {id}"
      }
    },
    "ipAddress": {
      "ipSearch": {
        "h1": "IP addresses",
        "form": {
          "input": {
            "ip": {
              "placeholder": "Search for an IP address",
              "validation": {
                "required": "The IP address is required."
              }
            }
          }
        },
        "tabs": {
          "creators": {
            "label": "Creators",
            "ip": "IP",
            "city": "City",
            "state": "State",
            "country": "Country",
            "created": "Created on",
            "hosting": "Is hosting",
            "proxy": "Is proxy",
            "list": {
              "label": "Creators",
              "id": "Creator id",
              "name": "Name",
              "email": "Email",
              "onboarding": "Onboarding",
              "approved": "Application approved",
              "incomplete": "Incomplete application",
              "paid": "Amount paid out",
              "flags": "Flags",
              "softban": "Softban",
              "suspended": "suspended"
            }
          },
          "logs": {
            "label": "Logs",
            "select": {
              "label": "Table",
              "creator": "Creator",
              "companies": "Companies",
              "campaigns": "Campaigns",
              "certificates": "Certificates",
              "jobs": "Jobs",
              "content": "Job content",
              "upsell": "Job upsell",
              "products": "Products",
              "tiktokAds": "TikTok Ads",
              "videos": "Videos",
              "campaignBundleCoupon": "Campaign bundle coupons",
              "stripe": "Stripe subscription"
            },
            "legend": "log | logs"
          },
          "companies": {
            "label": "Companies",
            "ip": "IP",
            "city": "City",
            "state": "State",
            "country": "Country",
            "created": "Created on",
            "hosting": "Is hosting",
            "proxy": "Is proxy",
            "list": {
              "label": "Companies",
              "id": "ID",
              "name": "Name",
              "email": "Email",
              "onboarding": "Onboarding",
              "paid": "Amount paid out",
              "suspended": "suspended"
            }
          }
        }
      }
    },
    "chat": {
      "meta": "Chat",
      "jobProduct": {
        "price": "Job price"
      },
      "card": {
        "title": "Chat with {0}",
        "issue": "Escalate issue",
        "sendMessage": "Write message...",
        "noMessages": "No messages yet!",
        "startConversation": "Start your conversation.",
        "endedConversation": "The chat is closed.",
        "status": {
          "canceled": "canceled",
          "finished": "finished"
        },
        "buttons": {
          "send": "Send",
          "back": "Back to Job",
          "backToCampaign": "Back to campaign",
          "goToCampaign": "Go to campaign",
          "go": "Go to Job",
          "extend": "Extend deadline",
          "cancel": "Cancel job",
          "tip": "Send a tip",
          "rehire": "Hire again",
          "unread": "Mark unread"
        },
        "rehireModal": {
          "title": "Rehire this creator?",
          "message": "A new campaign will auto-assign this creator, providing options for the type of content this creator will create.",
          "confirm": "Create a new job"
        },
        "successModal": {
          "message": "Your review has been sent. Thank you!"
        }
      },
      "modal": {
        "title": "Cancel this job",
        "form": {
          "label": "Description",
          "placeholder": "Please explain why you decided to cancel this job.",
          "maxChars": "Maximum 200 characters",
          "textArea": {
            "validation": {
              "required": "The description is required.",
              "min": "The reason must be greater than {min} characters.",
              "max": "The reason should not exceed {max} characters."
            }
          }
        },
        "buttons": {
          "cancel": "Cancel",
          "send": "Send"
        },
        "type": {
          "mediation": {
            "title": "This Job will be reviewed!",
            "message": "We will review your issue and we will send the resolution."
          },
          "reimbursement": {
            "title": "Product refund!",
            "message": "If you cancel the job right now, you will receive a full refund for the cost of the product."
          },
          "earnings": {
            "title": "Job refund!",
            "message": "If you cancel the job right now, you will receive a full refund for the cost of the job."
          }
        }
      },
      "noChats": {
        "h3": "All good!",
        "p": "Looks like everything is going smoothly.",
        "button": "Go to dashboard"
      },
      "flash": {
        "cancelled": "This job has been cancelled!"
      },
      "filter": {
        "all": "All chats",
        "job": "Jobs",
        "contract": "Contracts"
      },
      "bot": {
        "companyIsRequestedForReview": {
          "title": "The job has been successfully completed!",
          "text": "Loved working with {creatorName}? Leave a review! Share your experience to help creators improve their content!"
        },
        "creatorAcceptedJobInvitation": {
          "text": "{0} has accepted the job offer. New campaign is created."
        },
        "creatorDeclinedJobInvitation": {
          "text": "{0} has rejected the job offer. Sorry about that."
        },
        "companySubmittedReview": {
          "text": "Thank you for submitting a review!"
        },
        "companySentTip": {
          "text": "Your generous tip of ${0} has been successfully sent to {1}. Thank you!"
        },
        "companySentJobInvitation": {
          "text": "You have successfully sent the invitation. Wait for the creator to accept it."
        },
        "usedForbiddenWord": {
          "text": " Your message has not been delivered because it violates our {0}."
        },
        "chatCreated": {
          "firstMessage": "Welcome to the JoinBrands chat! This is your dedicated space to communicate with {0}. Dive into discussions, ask questions, and clarify any project details here.",
          "secondMessage": {
            "text": "{0} {1} {2} {3}",
            "secondMessageFirstPart": "Please note:",
            "secondMessageSecondPartBeforeLink": "This chat is the exclusive channel for your interactions with the creator. Requesting or sharing personal contact details such as emails, phone numbers, or any other means of off-platform communication is strictly prohibited and violates our",
            "secondMessageSecondPartAfterLink": ". Keep all interactions within this chat to maintain transparency and adhere to our guidelines."
          }
        },
        "recurringCampaign": {
          "recurringJobOffer": "Recurring job offer",
          "contentFrequency": "Content frequency: {0}",
          "videosPerMonth": "Videos per month: {0}",
          "monthlyPay": "Monthly pay: {0}",
          "companyCreateOfferNotification": {
            "text": "A recurring creator job offer has been successfully sent to {0}. Waiting for response, {0} is required to accept or counter the offer within 3 days, failing which the offer will expire."
          },
          "companyAcceptOffer": {
            "text": "You have accepted an offer from {0}."
          },
          "companyOfferExpired": {
            "text": "Your offer expired due to no response from the creator within the allotted time."
          },
          "companyStartTimeout": {
            "text": "The contract has expired because it was not started in the allotted time."
          },
          "companyRejectOffer": {
            "text": "You have rejected the counter offer."
          },
          "companyCancelContract": {
            "title": "Offer canceled",
            "text": "Your offer has been withdrawn, and {0} has been informed of this decision. If this was done in error, you can resend the offer."
          },
          "companyApprovedContent": {
            "text": "You have approved the content."
          },
          "companyRejectedContent": {
            "text": "You have rejected the content."
          },
          "companyAcceptPauseContract": {
            "text": "The contract will be paused from {startDate} to {endDate}."
          },
          "companyRejectPauseContract": {
            "text": "You have rejected the pause request."
          },
          "companyUnpauseContract": {
            "text": "The contract has been resumed."
          },
          "companyFinishedContract": {
            "text": "You have finished the contract. Once all the pending work has completed or expired we will issue a refund for the remaining pending content."
          },
          "creatorCancelContract": {
            "title": "Counteroffer canceled",
            "text": "{0} has withdrawn their counteroffer."
          },
          "creatorAcceptOffer": {
            "title": "{0 } has accepted the job offer",
            "features": {
              "monthlyPay": "MonthlyPay: {0}",
              "contentFrequency": "Content frequency: {0}"
            },
            "details": {
              "text": "You must start the job within 30 days, or it will be automatically canceled. To confirm the campaign and payment, please click the {0} button."
            }
          },
          "creatorCounterOfferNotification": {
            "text": "{0} offers you a counteroffer. See the job details."
          },
          "creatorRequestContentApproval": {
            "text1": "The creator has sent you content for approval.",
            "text2": "Content is done. Confirm it or send it for revision.",
            "button": {
              "approve": "Approve"
            }
          },
          "creatorRejectOffer": {
            "text": "{0} has rejected the job offer. Sorry about that."
          },
          "creatorOfferExpired": {
            "title": "Counteroffer expired",
            "text": "The counteroffer from {0} has expired due to no response within the allotted time."
          },
          "creatorRequestPauseContract": {
            "title": "{creatorName} requests a pause from {startDate} to {endDate}.",
            "text": "The reason for the pause is \"{reason}\".",
            "button": {
              "accept": "Accept",
              "reject": "Reject"
            }
          },
          "creatorRequestPauseContractExpired": {
            "text": "The pause request has been expired."
          },
          "creatorFinishedContract": {
            "text": "{0} has finished the contract."
          },
          "paymentFailed": {
            "text": "We were unable to charge your Credit card. Please update your payment method."
          },
          "shippingStart": {
            "text": "Congratulations on starting the job! Please proceed to send the product to the creator's address"
          },
          "shippingTracking": {
            "title": "Your tracking number",
            "text": "You can track your package with the following tracking number:"
          },
          "shippingTrackingNotification": {
            "title": "Your tracking number"
          },
          "shippingReceived": {
            "text": "Product received"
          },
          "noShippingRequired": {
            "text": "The contract is now active. No shipping is required."
          },
          "slotExpired": {
            "text": "The content for the {date} was not provided on time. The creator will not be paid for this day."
          }
        },
        "creatorCompletedFirstJob": {
          "firstText": "Congratulations on completing your first job with JoinBrands! {0} This is just the beginning of what promises to be a successful and creative journey. Your earnings are now available for withdrawal in your wallet, which you can access {1}.",
          "secondText": "We're always looking to improve, and your ideas are what drive us forward. If you have any suggestions or feature requests, we'd love to hear from you {0}.",
          "thirdText": "Also, if you're enjoying our app, please consider leaving a review. It's a great way to support us and helps other creators discover us too. iOS users can leave a review on the {0}, and Android users on the {1}. Your feedback is incredibly valuable and greatly appreciated. Thank you for being an integral part of our community!"
        },
        "companyExtendedDeadline": {
          "text": "{0} has extended the deadline to {1} for the job."
        },
        "contracts": {
          "recurringJobOffer": "Recurring job offer",
          "contentFrequency": "Content frequency: {0}",
          "videosPerMonth": "Videos per month: {0}",
          "monthlyPay": "Monthly pay: {0}",
          "chatInitialized": {
            "firstText": "We're thrilled to invite you to become a Recurring Creator for {0}. This opportunity means you'll be crafting content on a regular basis and get paid on regular basis.",
            "secondText": "Learn more about what it means to be a Recurring Creator and the opportunities it brings:",
            "link": "What are recurring creators?"
          },
          "companyCreateOffer": {
            "title": "{0} invites you to become their recurring content creator."
          },
          "companyRejectOffer": {
            "text": "{0} has rejected your counteroffer, sorry about that."
          },
          "companyAcceptPauseContract": {
            "text": "{0} has accepted your request to pause the contract from {1} to {2}."
          },
          "companyRejectPauseContract": {
            "text": "{0} has rejected your request to pause the contract."
          },
          "companyUnpauseContract": {
            "text": "The contract has been resumed."
          },
          "companyCancelContract": {
            "text": "{0} has canceled the contract, sorry about that."
          },
          "companyFinishedContract": {
            "text": "{0} has finished the contract, pending content should still be provided unless the brand instructed you not to."
          },
          "companyStartTimeout": {
            "text": "The contract has expired because it was not started in the allotted time."
          },
          "companyApprovedContent": {
            "text": "{0} has approved your content. Click to provide the social media post URL.",
            "button": {
              "provide": "Provide URL"
            }
          },
          "companyRejectedContent": {
            "text": "{0} rejected your content."
          },
          "companyAcceptOffer": {
            "title": "Brand accepted counteroffer",
            "text": "{0} has accepted your counteroffer. Please sign the contract to commence work."
          },
          "companyOfferExpired": {
            "text": "The offer has expired because you did not respond within the allotted time."
          },
          "creatorCounterOfferNotification": {
            "text": "Your counteroffer has been successfully sent to {0}. They have 7 days to respond. Should there be no response within this timeframe, the offer will automatically expire. Thank you!"
          },
          "creatorRequestPauseContract": {
            "text": "Your request to pause the contract has been successfully sent to {0}."
          },
          "creatorRequestPauseContractExpired": {
            "text": "Your pause request has expired due to no response from the brand within the allotted time."
          },
          "creatorCancelContract": {
            "text": "You canceled the contract with {0}."
          },
          "creatorFinishedContract": {
            "text": "You have finished the contract, you will still need to complete any pending work unless the brand instructed you not to do so."
          },
          "creatorAcceptOffer": {
            "text": "You have accepted the offer."
          },
          "creatorRejectOffer": {
            "text": "You have rejected the offer."
          },
          "creatorOfferExpired": {
            "text": "Your counteroffer has expired due to no response from the Brand within the allotted time."
          },
          "creatorRequestContentApproval": {
            "text": "You have sent content to the brand for approval."
          },
          "shippingStart": {
            "text": "The brand will be shipping the product to you shortly. Upon receiving the product, please click the button below to start the work. Feel free to use this chat to request any additional details you may need."
          },
          "shippingTrackingNotification": {
            "title": "Your tracking number",
            "description": "You can track your parcel using this code at {carrier}"
          },
          "shippingTracking": {
            "text": "A product has been sent to you. Confirm receipt in this chat.",
            "buttonLabel": "I have the product"
          },
          "shippingReceived": {
            "title": "Product received",
            "text": "I got the product. Ready to get to work."
          },
          "slotExpirationFirstWarningSent": {
            "text": "You didn't upload content on {date}. You can do it now."
          },
          "slotExpirationSecondWarningSent": {
            "text": "There's less than 24 hours left to upload content for {date}. After that, you will not be able to upload content."
          },
          "slotExpired": {
            "text": "The content for the {date} was not provided on time, you will not be paid for this day."
          }
        }
      },
      "flaggedChats": {
        "h1": "Flagged chats",
        "meta": {
          "title": "Flagged chats"
        },
        "table": {
          "columns": {
            "id": "Job ID",
            "created": "Created",
            "creator": "Creator",
            "company": "Company",
            "reason": "Reason"
          },
          "noRecords": "No flagged chats found.",
          "legend": "flagged chat | flagged chats"
        },
        "flash": {
          "reset": "The TOS violation has been reset for the job."
        }
      }
    }
  },
  "shared": {
    "modules": {
      "pageNotFound": {
        "views": {
          "pageNotFound": {
            "meta": {
              "title": "Page not found"
            },
            "h1": "Page Not Found",
            "url": "Please check the URL in the address bar and try again."
          }
        }
      }
    }
  }
}
