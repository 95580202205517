import i18n from "@/i18n";
import type { ApiServiceErrorValidationRule } from "@/core/shared/services/Error/ApiServiceError";

export class ApiErrorValidationTranslator {
  static translate(
    ruleName: string,
    rule: ApiServiceErrorValidationRule
  ): string {
    if (ruleName === "max_string_chars") {
      return i18n.global.t("common.error.validation.maxStringChars", rule.data);
    }

    return rule.message;
  }
}
